import React from "react";
import { useTranslate } from "@refinedev/core";
import {
    IconButton,
    Button,
    ButtonProps,
    InputProps,
    Input,
    Flex,
} from "@chakra-ui/react";
import { BiImport } from "react-icons/bi";

export interface ImportButtonProps extends ButtonProps {
    inputProps: InputProps;
    icon?: React.ReactElement;
    hideText?: boolean;
    resourceNameOrRouteName?: string;
    accessControl?: {
        enabled?: boolean;
        hideIfUnauthorized?: boolean;
    };
}

export const ImportButton: React.FC<ImportButtonProps> = ({
    inputProps,
    icon = <BiImport size={19} />,
    hideText = false,
    children,
    isLoading,
    resourceNameOrRouteName,
    ...rest
}) => {
    const translate = useTranslate();

    return (
        <label htmlFor="contained-button-file">
            <Input {...inputProps} id="contained-button-file" multiple hidden />
            <Flex direction="column">
                {hideText ? (
                    <IconButton
                        variant="action"
                        as="span"
                        aria-label={translate("buttons.import", "Import")}
                        isLoading={isLoading}
                        icon={icon}
                        {...rest}
                    ></IconButton>
                ) : (
                    <Button
                        variant="action"
                        as="span"
                        leftIcon={icon}
                        isLoading={isLoading}
                        {...rest}
                    >
                        {children ?? translate("buttons.import", "Import")}
                    </Button>
                )}
            </Flex>
        </label>
    );
};
