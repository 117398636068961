import {
    useDeleteButton,
    useDeleteMany,
    useTranslate,
    useUserFriendlyName
} from "@refinedev/core";
import React, { useState } from "react";

import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    ButtonProps,
    IconButton,
    useDisclosure,
} from "@chakra-ui/react";
import { UilTrash } from "@iconscout/react-unicons";
import type { RefineDeleteButtonProps } from "@refinedev/ui-types";

export interface DeleteButtonProps
    extends RefineDeleteButtonProps<ButtonProps> {
    bulkRecordItemIds?: Array<string>;
    confirmBody?: string;
    icon?: React.ReactElement;
}

export const DeleteButton = ({
    icon = <UilTrash size="18px" />,
    resource,
    recordItemId,
    bulkRecordItemIds,
    onSuccess,
    mutationMode: mutationModeProp,
    children,
    successNotification,
    errorNotification,
    hideText = false,
    accessControl,
    meta,
    metaData,
    invalidates,
    dataProviderName,
    confirmTitle,
    confirmBody,
    confirmOkText,
    confirmCancelText,
    ...props
}: DeleteButtonProps) => {
    const {
        onConfirm: onConfirmFromProps,
        label,
        title,
        disabled,
        hidden,
        loading,
        confirmTitle: defaultConfirmTitle,
        confirmOkLabel: defaultConfirmOkLabel,
        cancelLabel: defaultCancelLabel,
    } = useDeleteButton({
        resource,
        id: recordItemId,
        onSuccess,
        mutationMode: mutationModeProp,
        successNotification,
        errorNotification,
        accessControl,
        meta,
        dataProviderName,
        invalidates,
    });

    if (hidden) return null;

    const translate = useTranslate();
    const [opened, setOpened] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);
    const { mutate, isLoading: isBulkLoading } = useDeleteMany();
    const getFriendlyName = useUserFriendlyName();
    const isBulkDelete = !!bulkRecordItemIds;

    const onConfirm = () => {
        setOpened(false);
        if (isBulkDelete) {
            mutate(
                {
                    ids: bulkRecordItemIds,
                    resource: resource!,
                    meta: meta,
                    mutationMode: mutationModeProp,
                },
                {
                    onSuccess: () => {
                        onClose();
                        if (onSuccess) {
                            onSuccess({ data: { ids: recordItemId } });
                        }
                    },
                }
            );
            return;
        } else {
            onConfirmFromProps();
        }
    };

    const onDelete = (event: any) => {
        event.stopPropagation();
        onOpen();
    };

    return (
        <>
            {hideText ? (
                <IconButton
                    icon={icon}
                    variant="destructive"
                    aria-label={title}
                    onClick={onDelete}
                    isDisabled={isBulkLoading || loading || disabled}
                    isLoading={isBulkLoading || loading}
                    {...props}
                ></IconButton>
            ) : (
                <Button
                    variant="destructive"
                    onClick={onDelete}
                    isDisabled={isBulkLoading || loading || disabled}
                    isLoading={isBulkLoading || loading}
                    leftIcon={icon}
                    {...props}
                >
                    {children ?? label}
                </Button>
            )}

            <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
            >
                <AlertDialogOverlay />
                <AlertDialogContent>
                    <AlertDialogHeader>
                        {confirmBody ?? translate("buttons.delete", "Delete")}
                        {isBulkDelete ? ` ${bulkRecordItemIds.length} ` : " "}
                        {translate(
                            isBulkDelete
                                ? `${resource}.titles.list`
                                : `${resource}.${resource}`,
                            getFriendlyName(
                                resource,
                                isBulkDelete ? "plural" : "singular"
                            )
                        )}
                        ?
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        {confirmTitle ?? defaultConfirmTitle}
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            {confirmCancelText ?? defaultCancelLabel}
                        </Button>
                        <Button
                            colorScheme="red"
                            ml={3}
                            onClick={onConfirm}
                            isLoading={isBulkLoading || loading}
                        >
                            {confirmOkText ?? defaultConfirmOkLabel}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </>
    );
};
