import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import {
    useCreateButton
} from "@refinedev/core";
import type { RefineCreateButtonProps } from "@refinedev/ui-types";
import React from "react";
import { FiPlus } from "react-icons/fi";

interface CreateButtonProps extends RefineCreateButtonProps<ButtonProps> {
    icon?: React.ReactElement;
}

export const CreateButton = ({
    icon = <FiPlus size="18px" />,
    resource: resourceNameFromProps,
    hideText = false,
    accessControl,
    children,
    meta,
    onClick,
    ...props
}: CreateButtonProps) => {
    const { to, label, title, hidden, disabled, canAccess, LinkComponent } =
        useCreateButton({
            resource: resourceNameFromProps,
            accessControl,
            meta,
        });

    if (hidden) return null;

    return (
        <LinkComponent
            to={to}
            replace={false}
            onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                if (disabled) {
                    e.preventDefault();
                    return;
                }
                if (onClick) {
                    e.preventDefault();
                    onClick(e);
                }
            }}
        >
            {hideText ? (
                <IconButton
                    variant="primary"
                    aria-label={label}
                    title={title}
                    isDisabled={disabled}
                    icon={icon}
                    onClick={onClick}
                    {...props}
                />
            ) : (
                <Button
                    variant="primary"
                    isDisabled={disabled}
                    leftIcon={icon}
                    title={title}
                    onClick={onClick}
                    {...props}
                >
                    {children ?? label}
                </Button>
            )}
        </LinkComponent>
    );
};
