import { formatBytes } from "@bankingright-dashboard/utils";
import {
    Box,
    BoxProps,
    Image as ChakraImage,
    Flex,
    HStack,
    IconButton,
    Link,
    Text,
    VStack,
} from "@chakra-ui/react";
import { UilTimes, UilFile } from "@iconscout/react-unicons";
import { format } from "date-fns";

interface AttachmentProps extends BoxProps {
    filename: string;
    url?: string;
    size?: number;
    hasDelete?: boolean;
    onDelete?: () => void;
}

export const AttachmentCard = ({
    filename,
    url,
    size,
    onDelete,
    hasDelete = true,
    ...props
}: AttachmentProps) => {
    return (
        <HStack
            border="1px"
            borderColor="defaults.border-divider"
            shadow="sm"
            rounded="md"
            bg="blue.100"
            spacing={3}
            position="relative"
            py={3}
            pl={3}
            pr={hasDelete ? 12 : 3}
            {...props}
        >
            {hasDelete && (
                <IconButton
                    position="absolute"
                    top={1}
                    right={1}
                    variant="ghost"
                    aria-label="close"
                    icon={<UilTimes size={18} />}
                    size="sm"
                    onClick={onDelete}
                />
            )}
            <Box
                bg="white"
                p={2}
                border="1px"
                borderColor="defaults.border-divider"
                rounded="md"
            >
                <UilFile size={20} />
            </Box>
            <VStack align="flex-start" spacing={0}>
                {url == undefined ? (
                    <Text
                        fontWeight="semibold"
                        fontSize="sm"
                        w="100%"
                        noOfLines={1}
                    >
                        {filename}
                    </Text>
                ) : (
                    <Link href={url} isExternal>
                        <Text
                            fontWeight="semibold"
                            fontSize="sm"
                            w="100%"
                            noOfLines={1}
                        >
                            {filename}
                        </Text>
                    </Link>
                )}
                {size && (
                    <Text mb={-1} fontSize="sm" color="gray.500">
                        {formatBytes(size)}
                    </Text>
                )}
            </VStack>
        </HStack>
    );
};
