import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import { useEditButton } from "@refinedev/core";
import type { RefineEditButtonProps } from "@refinedev/ui-types";
import React from "react";
import { FiEdit } from "react-icons/fi";

interface EditButtonProps extends RefineEditButtonProps<ButtonProps> {
    icon?: React.ReactElement;
}

export const EditButton = ({
    icon = <FiEdit />,
    resource: resourceNameFromProps,
    hideText = false,
    recordItemId,
    accessControl,
    children,
    meta,
    onClick,
    ...props
}: EditButtonProps) => {
    const { to, label, title, hidden, disabled, canAccess, LinkComponent } =
        useEditButton({
            resource: resourceNameFromProps,
            id: recordItemId,
            accessControl,
            meta,
        });

    if (hidden) return null;

    return (
        <LinkComponent
            to={to}
            replace={false}
            onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                if (disabled) {
                    e.preventDefault();
                    return;
                }
                if (onClick) {
                    e.preventDefault();
                    onClick(e);
                }
            }}
        >
            {hideText ? (
                <IconButton
                    variant="action"
                    aria-label={label}
                    title={title}
                    isDisabled={disabled}
                    icon={icon}
                    onClick={onClick}
                    w="100%"
                    {...props}
                />
            ) : (
                <Button
                    variant="action"
                    isDisabled={disabled}
                    leftIcon={icon}
                    title={title}
                    onClick={onClick}
                    w="100%"
                    {...props}
                >
                    {children ?? label}
                </Button>
            )}
        </LinkComponent>
    );
};
