import {
    AccessAuditOperationType,
    FilterType,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { AccessAudit } from "@bankingright-dashboard/interfaces";
import {
    FilterConfiguration,
    ResourceList,
} from "@bankingright-dashboard/table";
import {
    convertToDate,
    generateAccessAuditTypeScheme,
} from "@bankingright-dashboard/utils";
import { Badge, Link as ChakraLink, HStack, Text } from "@chakra-ui/react";
import { useLink, useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const AccessAuditList: React.FC = () => {
    const translate = useTranslate();
    const Link = useLink();

    const columns = React.useMemo<ColumnDef<AccessAudit>[]>(
        () => [
            {
                id: "operationType",
                header: "Type",
                accessorKey: "operationType",
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.select,
                        options: Object.values(AccessAuditOperationType),
                    },
                },
                cell: function render({ getValue }) {
                    const type = getValue() as AccessAuditOperationType;
                    return (
                        <Badge
                            colorScheme={generateAccessAuditTypeScheme(type)}
                        >
                            {type}
                        </Badge>
                    );
                },
            },
            {
                id: "startDate",
                header: "Date",
                accessorKey: "startDate",
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <HStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </HStack>
                    );
                },
            },
            {
                id: "user_id",
                header: "User ID",
                accessorKey: "userId",
                enableColumnFilter: true,
            },
            {
                id: "target.type",
                header: "Target",
                accessorKey: "target.type",
                enableColumnFilter: true,
            },
            {
                id: "operation_id",
                header: "Operation ID",
                accessorKey: "operationId",
                enableColumnFilter: true,
                cell: function render(props) {
                    return (
                        <ChakraLink
                            whiteSpace="nowrap"
                            as={Link}
                            to={`/logs/show/${
                                props.getValue() as string
                            }/operation`}
                            onClick={(event: any) => {
                                event.stopPropagation();
                            }}
                            onMouseUp={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            {props.getValue() as string}
                        </ChakraLink>
                    );
                },
            },
        ],
        []
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "id",
            label: "ID",
            type: FilterType.input,
        },
    ];

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.show]}
            additionalFilters={additionalFilters}
            refineCoreProps={{ pagination: { pageSize: 25 } }}
            size="sm"
        />
    );
};
