import { Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { CrudFilters, useShow } from "@refinedev/core";

import {
    NotificationType,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { EmailTemplate, IDevice, IUser } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { DetailItem, Show } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { ResetPassword } from "./actions";
import { SendNotification } from "./actions/send-notification";

interface UserShowProps {
    emailTemplates?: EmailTemplate[];
}

export const UserShow: React.FC<UserShowProps> = ({ emailTemplates }) => {
    const { query } = useShow<IUser>();
    const { data, isLoading } = query;
    const user = data?.data;

    const filters: CrudFilters = [
        {
            field: "user_id",
            operator: "eq",
            value: user?.id || "",
        },
    ];

    const columns = React.useMemo<ColumnDef<IDevice>[]>(
        () => [
            {
                id: "id",
                header: "ID",
                accessorKey: "id",
                enableSorting: false,
            },
            {
                id: "deviceName",
                header: "Device Name",
                accessorKey: "deviceName",
                enableSorting: false,
            },
            {
                id: "platform",
                header: "Platform",
                accessorKey: "platform",
                enableSorting: false,
            },
            {
                id: "dateModified",
                header: "Last used",
                accessorKey: "dateModified",
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <HStack>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </HStack>
                    );
                },
            },
        ],
        []
    );

    return (
        <>
            <VStack align="stretch" spacing={5}>
                <Show isLoading={isLoading}>
                    <VStack align="flex-start" spacing={3}>
                        <DetailItem title="Id" value={user?.id} />
                        <DetailItem title="Username" value={user?.userName} />
                        <DetailItem title="Name" value={user?.fullName} />
                        <DetailItem
                            title="Status"
                            value={user?.status ?? "active"}
                        />
                        <DetailItem
                            title="Onboarding Status"
                            value={user?.onboardingStatus}
                        />
                        <DetailItem
                            title="Email"
                            value={user?.email}
                            showVerification={user?.emailVerified}
                        />
                        <DetailItem
                            title="Mobile phone"
                            value={user?.mobilePhone}
                            showVerification={user?.mobilePhoneVerified}
                        />
                        <VStack align="flex-start">
                            <Heading as="h5" size="sm">
                                Created at
                            </Heading>
                            <Text>
                                {convertToDate(
                                    user?.dateCreated
                                ).toLocaleDateString()}{" "}
                                {convertToDate(
                                    user?.dateCreated
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>

                        {user?.dateModified && (
                            <VStack align="flex-start">
                                <Heading as="h5" size="sm">
                                    Last modified at
                                </Heading>
                                <Text>
                                    {convertToDate(
                                        user?.dateModified
                                    ).toLocaleDateString()}{" "}
                                    {convertToDate(
                                        user?.dateModified
                                    ).toLocaleTimeString()}
                                </Text>
                            </VStack>
                        )}
                        {user && (
                            <VStack align="flex-start">
                                <Heading as="h5" size="sm">
                                    Actions
                                </Heading>
                                <VStack spacing={3} align="flex-start">
                                    <ResetPassword user={user} />
                                    <SendNotification
                                        user={user}
                                        notificationType={
                                            NotificationType.email
                                        }
                                        templates={emailTemplates}
                                    />
                                    <SendNotification
                                        user={user}
                                        notificationType={NotificationType.sms}
                                    />
                                    <SendNotification
                                        user={user}
                                        notificationType={NotificationType.push}
                                    />
                                </VStack>
                            </VStack>
                        )}
                    </VStack>
                </Show>
                <ResourceList
                    title="Linked Devices"
                    columns={columns}
                    actions={[ResourceAction.show, ResourceAction.delete]}
                    resourceName="devices"
                    refineCoreProps={{
                        permanentFilter: filters,
                        resource: "devices",
                        initialPageSize: 5,
                    }}
                    clearWhenLoading={true}
                    showPageSizeSelect={false}
                />
            </VStack>
        </>
    );
};
