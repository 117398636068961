import { AccessControlResource } from "@bankingright-dashboard/enums";
import {
    IUser,
    ResetPasswordResponse,
} from "@bankingright-dashboard/interfaces";
import { PasswordInputFormControl } from "@bankingright-dashboard/table";
import {
    Button,
    ButtonProps,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
    Text,
} from "@chakra-ui/react";
import { UilRedo } from "@iconscout/react-unicons";
import {
    useApiUrl,
    useCan,
    useCustomMutation,
    useNavigation,
    useNotification,
    useResource,
} from "@refinedev/core";
import { useForm } from "react-hook-form";

interface ResetPasswordProps extends ButtonProps {
    user: IUser;
    icon?: React.ReactElement;
}

type ResetPasswordFormValues = {
    password: string;
    repeatPassword: string;
};

export const ResetPassword: React.FC<ResetPasswordProps> = ({
    user,
    icon = <UilRedo size={18} />,
    ...props
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { open } = useNotification();
    const { resource } = useResource();
    const apiUrl = useApiUrl();
    const url = `${apiUrl}/v1/${resource!.name}/${user.id}/resetpassword`;
    const { control, handleSubmit, reset } = useForm<ResetPasswordFormValues>({
        defaultValues: {
            password: undefined,
            repeatPassword: undefined,
        },
    });

    const { mutate, isLoading } = useCustomMutation<ResetPasswordResponse>();

    const { data: canEditUser } = useCan({
        resource: AccessControlResource.users,
        action: "edit",
    });

    const onSubmit = async ({ password }: ResetPasswordFormValues) => {
        mutate(
            {
                url: url,
                method: "post",
                values: {
                    password: password,
                },
            },
            {
                onSuccess: () => {
                    open?.({
                        type: "success",
                        message: "Reset password successfully.",
                        description:
                            "The password of the user has been reset successfully.",
                    });
                    onCloseModal();
                },
            }
        );
    };

    const onCloseModal = () => {
        reset();
        onClose();
    };

    return (
        <>
            <Button
                variant="tertiary"
                leftIcon={icon}
                onClick={onOpen}
                aria-label="Reset Password"
                title={!canEditUser?.can ? `You don't have permission to reset the password` : undefined}
                isDisabled={!canEditUser?.can}
                {...props}
            >
                Reset Password
            </Button>
            <Modal
                isOpen={isOpen}
                closeOnOverlayClick={false}
                onClose={onCloseModal}
            >
                <ModalOverlay />
                <ModalContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>Reset Password</ModalHeader>
                        <ModalCloseButton isDisabled={isLoading} />
                        <ModalBody>
                            <VStack align="flex-start" spacing={5}>
                                <Text>
                                    You are about to reset the password for user{" "}
                                    {user?.userName}. Please enter the new
                                    password:
                                </Text>
                                <PasswordInputFormControl
                                    control={control}
                                    name={"password"}
                                />
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant="primary"
                                mr={3}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="ghost"
                                onClick={onCloseModal}
                                isDisabled={isLoading}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};
