import {
    useFileExport,
    useFileImport
} from "@bankingright-dashboard/hooks";
import {
    IUserSegment
} from "@bankingright-dashboard/interfaces";
import {
    ButtonWithLabelProps,
    DetailItem,
    Show
} from "@bankingright-dashboard/ui";
import { VStack } from "@chakra-ui/react";
import {
    useNavigation,
    useResource,
    useShow
} from "@refinedev/core";
import { FiUsers } from "react-icons/fi";

export const UserSegmentShow: React.FC = () => {
    const { query } = useShow<IUserSegment>();
    const { data, isLoading, isFetching } = query;
    const segment = data?.data;
    const { id, resource } = useResource();
    const { list } = useNavigation();

    const importProps = useFileImport({
        id: id!,
        meta: {
            parent: "users",
        },
        onFinish: () => {
            query.refetch();
        },
    });

    const exportProps = useFileExport({
        id: id!,
        meta: {
            parent: "users",
        },
    });

    const usersButton: ButtonWithLabelProps = {
        label: "Users",
        leftIcon: <FiUsers />,
        variant: "tertiary",
        "aria-label": "Users",
        onClick: () => {
            list("userssegments-users");
        },
    };

    return (
        <Show
            isLoading={isLoading || isFetching}
            importButtonProps={importProps}
            exportButtonProps={exportProps}
            additionalButtons={[usersButton]}
            canAction={(action) => {
                if (action === "edit" && (data?.data?.total ?? 0) > 50) {
                    return false;
                } else {
                    return true;
                }
            }}
            meta={{ parent: "users" }}
        >
            <VStack spacing={3} align="flex-start">
                <DetailItem title="Id" value={segment?.id} />
                <DetailItem title="Title" value={segment?.title} />
                <DetailItem title="Description" value={segment?.description} />
                <DetailItem title="Number of users" value={segment?.total} />
            </VStack>
        </Show>
    );
};
