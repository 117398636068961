import { VStack } from "@chakra-ui/react";
import { useShow } from "@refinedev/core";

import {
    AccessAudit
} from "@bankingright-dashboard/interfaces";
import { DetailItem, DetailItemType, Show } from "@bankingright-dashboard/ui";

export const AccessAuditShow: React.FC = () => {
    const { query } = useShow<AccessAudit>();
    const { data, isLoading } = query;
    const audit = data?.data;

    return (
        <Show isLoading={isLoading}>
            <VStack align="flex-start" spacing={3}>
                <DetailItem title="ID" value={audit?.id} />
                <DetailItem title="Operation ID" value={audit?.operationId} />
                <DetailItem title="User ID" value={audit?.userId} />
                <DetailItem title="User agent" value={audit?.userAgent} />
                <DetailItem
                    title="Start Date"
                    value={audit?.startDate}
                    type={DetailItemType.date}
                    showTime={true}
                />
                <DetailItem
                    title="End Date"
                    value={audit?.endDate}
                    type={DetailItemType.date}
                    showTime={true}
                />
                <DetailItem title="Type" value={audit?.operationType} />
                <DetailItem title="Exception" value={audit?.exception} />
                <DetailItem title="Target" value={audit?.target.type} />
            </VStack>
        </Show>
    );
};
