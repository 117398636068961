import { EmailTemplate } from "@bankingright-dashboard/interfaces";

export const BankingRightEmailTemplates: EmailTemplate[] = [
    {
        id: "d-790f960c36f24e9199419471a823d94b",
        name: "Default",
        attributes: [
            {
                name: "Subject",
                key: "subject",
                type: "input",
            },
            {
                name: "Preheader",
                key: "preheader",
                type: "input",
            },
            {
                name: "Title",
                key: "title",
                type: "input",
            },
            {
                name: "Message body",
                key: "body",
                type: "textarea",
            },
        ],
    },
];
