import { normalize } from "@bankingright-dashboard/utils";
import {
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
    HStack,
} from "@chakra-ui/react";
import {
    useResource,
    useTranslate,
    useUserFriendlyName
} from "@refinedev/core";
import { BackButton } from "./back-button";
import { LoadingIndicator } from "./loading-indicator";
import { SaveButton, SaveButtonProps } from "./save-button";

interface CloneProps {
    isLoading: boolean;
    saveButtonProps: SaveButtonProps;
    children: any;
}

export const Clone: React.FC<CloneProps> = ({
    isLoading,
    saveButtonProps,
    children,
}) => {
    const translate = useTranslate();
    const { id, resource } = useResource();
    const getFriendlyName = useUserFriendlyName();

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <HStack spacing={2}>
                    <BackButton />
                    <Heading size="lg">
                        {translate(
                            `${normalize(resource!.name)}.titles.clone`,
                            `Edit ${getFriendlyName(
                                resource!.name,
                                "singular"
                            )}`
                        )}
                    </Heading>
                </HStack>
            </CardHeader>
            <CardBody>
                <Box opacity={isLoading ? 0.5 : undefined}>{children}</Box>
                <LoadingIndicator isLoading={isLoading} />
            </CardBody>
            <CardFooter justify="flex-end">
                <HStack spacing={2}>
                    <SaveButton {...saveButtonProps} />
                </HStack>
            </CardFooter>
        </Card>
    );
};
