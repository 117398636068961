import { FormType } from "@bankingright-dashboard/enums";
import {
    IPushNotification,
    IUserSegment,
} from "@bankingright-dashboard/interfaces";
import {
    CreatableSelectFormControl,
    DatePickerFormControl,
    MarkdownEditorFormControl,
    InputFormControl,
    InputFormType,
    CreatableSelectType,
    SelectFormControl,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useMeta, useParsed, useSelect, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import React, { useState } from "react";

interface PushNotificationFormProps {
    type: FormType;
}

export const PushNotificationForm = ({ type }: PushNotificationFormProps) => {
    const translate = useTranslate();
    const { params } = useParsed();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef(null);
    const {
        getValues,
        control,
        refineCore: { formLoading, onFinish },
        saveButtonProps,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IPushNotification>({
        defaultValues: {
            userIds: params?.userId ? [params.userId] : undefined,
        },
    });

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        const { userIds, userSegments, deviceIds } = getValues();
        if (
            (userIds == undefined || userIds?.length == 0) &&
            (userSegments == undefined || userSegments?.length == 0) &&
            (deviceIds == undefined || deviceIds?.length == 0)
        ) {
            onOpen();
            return;
        } else {
            handleSubmit(onFinish)();
        }
    };

    const onConfirm = () => {
        onClose();
        handleSubmit(onFinish)();
    };

    const segmentSelectProps = useSelect<IUserSegment>({
        resource: "segments",
        meta: {
            parent: "users",
        },
    });

    const form = () => {
        return (
            <VStack spacing={3}>
                {type == FormType.create && (
                    <>
                        <CreatableSelectFormControl
                            control={control}
                            id="userIds"
                            name="userIds"
                            label="User ID(s)"
                            placeholder="Keep empty to send to all users"
                            isRequired={false}
                            type={CreatableSelectType.array}
                        />
                        <SelectFormControl
                            control={control}
                            id="userSegments"
                            name="userSegments"
                            label="User Segments(s)"
                            isMulti={true}
                            placeholder="Keep empty to send to all users"
                            type={CreatableSelectType.array}
                            isRequired={false}
                            {...segmentSelectProps}
                        />
                        <CreatableSelectFormControl
                            control={control}
                            id="deviceIds"
                            name="deviceIds"
                            label="Device ID(s)"
                            placeholder="Keep empty to send to all devices (of the specified users if provided)"
                            isRequired={false}
                            type={CreatableSelectType.array}
                        />
                    </>
                )}
                <DatePickerFormControl
                    control={control}
                    id="date"
                    name="date"
                    label="Delivery date"
                    isRequired={false}
                    showTime={true}
                />
                <InputFormControl
                    name="title"
                    label="Title"
                    control={control}
                />
                <InputFormControl name="body" label="Body" control={control} />
                <InputFormControl
                    name={"badgeCount"}
                    label={"Badge count"}
                    control={control}
                    isRequired={false}
                    rules={{
                        pattern: {
                            value: RegExp("^[0-9]*$"),
                            message: "Only numbers are allowed",
                        },
                    }}
                />
                <InputFormControl
                    name="customData"
                    label="Custom data"
                    control={control}
                    isRequired={false}
                    type={InputFormType.textarea}
                />
                <FormControl mt={5} isInvalid={!!errors?.status}>
                    <FormLabel>Additional options:</FormLabel>
                    <VStack align="flex-start">
                        <Checkbox id="silent" {...register("silent")}>
                            Send silently
                        </Checkbox>
                        <Checkbox id="immediate" {...register("immediate")}>
                            Send immediately
                        </Checkbox>
                    </VStack>
                </FormControl>
                <AlertDialog
                    motionPreset="slideInBottom"
                    leastDestructiveRef={cancelRef}
                    onClose={onClose}
                    isOpen={isOpen}
                    isCentered
                >
                    <AlertDialogOverlay />
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            Broadcast push notification
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            Are you sure you want to broadcast this push
                            notification to all users?
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                {translate("buttons.cancel", "Cancel")}
                            </Button>
                            <Button
                                ml={3}
                                onClick={onConfirm}
                                variant="primary"
                            >
                                {translate("buttons.save", "Save")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
        default:
            return <></>;
    }
};
