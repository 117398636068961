import { ResourceAction } from "@bankingright-dashboard/enums";
import { IUserSegment } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const UserSegmentList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IUserSegment>[]>(
        () => [
            {
                id: "title",
                header: "Title",
                accessorKey: "title",
                enableColumnFilter: true,
                meta: {
                    width: "50%",
                },
            },
            {
                id: "total",
                header: "Total users",
                accessorKey: "total",
                enableColumnFilter: true,
                meta: {
                    width: "50%",
                },
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.show,
                ResourceAction.edit,
                ResourceAction.delete,
            ]}
            hasSelection={true}
            canAction={(row, action) => {
                if (action === ResourceAction.edit && row.original.total > 50) {
                    return false;
                }
                return true;
            }}
        />
    );
};
