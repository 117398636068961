import { FormType } from "@bankingright-dashboard/enums";
import { IUserSegment } from "@bankingright-dashboard/interfaces";
import {
    CreatableSelectFormControl,
    CreatableSelectType,
    InputFormControl,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { CrudFilters, useImport, useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";

interface UserSegmentFormProps {
    type: FormType;
}

export const UserSegmentForm = ({ type }: UserSegmentFormProps) => {
    const translate = useTranslate();

    const filters: CrudFilters = [
        {
            field: "include_users",
            operator: "eq",
            value: true,
        },
    ];
    const {
        refineCore: { formLoading },
        control,
        saveButtonProps,
    } = useForm<IUserSegment>({
        refineCoreProps: {
            meta: {
                filters: filters,
            },
        },
    });

    const form = () => {
        return (
            <>
                <InputFormControl
                    name={"title"}
                    label={"Title"}
                    control={control}
                />
                <InputFormControl
                    name={"description"}
                    label={"Description"}
                    control={control}
                    isRequired={false}
                />
                <CreatableSelectFormControl
                    control={control}
                    id="users"
                    name="users"
                    label="Users"
                    placeholder="Please provide user ids here"
                    type={CreatableSelectType.array}
                />
            </>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
        default:
            return <></>;
    }
};
