import {
    UilAnalytics,
    UilBell,
    UilClipboardNotes,
    UilCloudCheck,
    UilCog,
    UilComparison,
    UilHeartMedical,
    UilImages,
    UilKeyholeCircle,
    UilLanguage,
    UilLinkAdd,
    UilLockAlt,
    UilMegaphone,
    UilMobileAndroid,
    UilMobileVibrate,
    UilProcess,
    UilShield,
    UilUserCheck,
    UilUsersAlt,
} from "@iconscout/react-unicons";
import { ResourceProps } from "@refinedev/core";
import { FiHome, FiMail } from "react-icons/fi";

export const ShowcaseResources: ResourceProps[] = [
    {
        name: "calltoaction",
        list: "/calltoaction",
        show: "/calltoaction/show/:id",
        create: "/calltoaction/create",
        edit: "/calltoaction/edit/:id",
        clone: "/calltoaction/clone/:id",
        meta: {
            icon: <UilMegaphone size="18px" />,
            canDelete: true,
            category: "configuration",
            supportsBulkDelete: true,
        },
    },
    {
        name: "appconfiguration",
        list: "/appconfiguration",
        edit: "/appconfiguration/edit/:id",
        meta: {
            icon: <UilCog size="18px" />,
            category: "configuration",
        },
    },
    {
        name: "configuration",
        list: "/wealthforecast/configuration",
        meta: {
            icon: <UilComparison size="18px" />,
            parent: "wealthforecast",
            category: "configuration",
        },
    },
    {
        name: "users",
        list: "/users",
        show: "/users/show/:id",
        create: "/users/create",
        edit: "/users/edit/:id",
        meta: {
            icon: <UilUsersAlt size="18px" />,
            canDelete: true,
            category: "user",
            supportsBulkDelete: true,
        },
    },
    {
        name: "segments",
        list: "/users/segments",
        show: "/users/segments/show/:id",
        create: "/users/segments/create",
        edit: "/users/segments/edit/:id",
        meta: {
            icon: <UilUserCheck size="18px" />,
            canDelete: true,
            parent: "users",
            category: "user",
            supportsBulkDelete: true,
        },
    },
    {
        identifier: "userssegments-users",
        name: "users",
        list: "/users/segments/show/:id/users",
        meta: {
            parent: "users/segments",
            parentHasId: true,
            accessControlName: "userssegments",
        },
    },
    {
        name: "devices",
        list: "/devices",
        show: "/devices/show/:id",
        meta: {
            icon: <UilMobileAndroid size="18px" />,
            canDelete: true,
            category: "user",
            supportsBulkDelete: true,
        },
    },
    {
        name: "messages",
        list: "/messages",
        show: "/messages/show/:id",
        create: "/messages/create",
        edit: "/messages/edit/:id",
        meta: {
            icon: <FiMail size="18px" />,
            canDelete: true,
            category: "content",
            supportsBulkDelete: true,
        },
    },
    {
        identifier: "messages-users",
        name: "users",
        list: "/messages/show/:id/users",
        meta: {
            parent: "messages",
            parentHasId: true,
            accessControlName: "messages",
        },
    },
    {
        name: "pushnotifications",
        list: "/pushnotifications",
        create: "/pushnotifications/create",
        meta: {
            icon: <UilMobileVibrate size="18px" />,
            canDelete: true,
            category: "content",
            supportsBulkDelete: true,
        },
    },
    {
        name: "enrichments",
        list: "/positions/enrichments",
        create: "/positions/enrichments/create",
        show: "/positions/enrichments/show/:id",
        edit: "/positions/enrichments/edit/:id",
        meta: {
            icon: <UilLinkAdd size="18px" />,
            parent: "positions",
            canDelete: true,
            category: "content",
        },
    },
    {
        name: "approvalrequests",
        list: "/signingtasks/approvalrequests",
        show: "/signingtasks/approvalrequests/show/:id",
        create: "/signingtasks/approvalrequests/create",
        meta: {
            icon: <UilCloudCheck size="18px" />,
            parent: "signingtasks",
            canDelete: true,
            category: "content",
            supportsBulkDelete: true,
        },
    },
    {
        name: "contenttriggers",
        list: "/contenttriggers",
        create: "/contenttriggers/create",
        show: "/contenttriggers/show/:id",
        edit: "/contenttriggers/edit/:id",
        clone: "/contenttriggers/clone/:id",
        meta: {
            icon: <UilBell size="18px" />,
            canDelete: true,
            category: "content",
            supportsBulkDelete: true,
        },
    },
    {
        identifier: "contenttriggers-users",
        name: "users",
        list: "/contenttriggers/show/:id/users",
        meta: {
            parent: "contenttriggers",
            parentHasId: true,
            accessControlName: "contenttriggers",
            supportsBulkDelete: true,
        },
    },
    {
        name: "statistics",
        list: "/statistics",
        meta: {
            icon: <UilAnalytics size="18px" />,
            category: "insight",
        },
    },
    {
        name: "logs",
        list: "/logs",
        show: "/logs/show/:id",
        meta: {
            icon: <UilClipboardNotes size="18px" />,
            category: "insight",
        },
    },
    {
        name: "audits",
        list: "/security/audits",
        show: "/security/audits/show/:id",
        meta: {
            icon: <UilShield size="18px" />,
            parent: "security",
            category: "insight",
        },
    },
    {
        name: "localizations",
        list: "/localizations",
        // show: "/localizations/show/:id",
        meta: {
            icon: <UilLanguage size="18px" />,
            category: "administration",
        },
    },
    {
        name: "processes",
        list: "/processes",
        meta: {
            icon: <UilProcess size="18px" />,
            category: "administration",
        },
    },
    {
        name: "accesscontrol",
        list: "/accesscontrol",
        create: "/accesscontrol/create",
        show: "/accesscontrol/show/:id",
        edit: "/accesscontrol/edit/:id",
        meta: {
            icon: <UilLockAlt size="18px" />,
            canDelete: true,
            category: "administration",
        },
    },
    {
        name: "healthchecks",
        list: "/healthchecks",
        meta: {
            icon: <UilHeartMedical size="18px" />,
            category: "administration",
        },
    },
    {
        name: "images",
        list: "/images",
        create: "/images/create",
        show: "/images/show/:id",
        edit: "/images/edit/:id",
        meta: {
            icon: <UilImages size="18px" />,
            canDelete: true,
            category: "content",
            supportsBulkCreate: true,
            supportsBulkDelete: true,
        },
    },
    {
        name: "accessaudits",
        list: "/accessaudits",
        show: "/accessaudits/show/:id",
        meta: {
            icon: <UilKeyholeCircle size="18px" />,
            category: "administration",
        },
    },
];
