import {
    AccessControlResource,
    AccessControlRole,
    FormType,
} from "@bankingright-dashboard/enums";
import {
    IAccessControl,
    IAccessControlAccess,
} from "@bankingright-dashboard/interfaces";
import {
    CheckboxControl,
    InputFormControl,
    SelectFormControl,
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { covertToOptionArray } from "@bankingright-dashboard/utils";
import {
    Box,
    Checkbox,
    FormLabel,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { HttpError } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { useFieldArray } from "react-hook-form";

interface AccessControlFormProps {
    type: FormType;
}

export const AccessControlForm = ({ type }: AccessControlFormProps) => {
    const defaultValues = {
        id: undefined,
        userId: undefined,
        role: undefined,
        access: Object.keys(AccessControlResource).map((key) => {
            return {
                resource: key,
                create: false,
                read: false,
                update: false,
                delete: false,
            };
        }),
    };

    const {
        getValues,
        control,
        refineCore: { formLoading, queryResult, onFinish },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
        watch,
        handleSubmit,
    } = useForm<IAccessControl>({
        defaultValues,
    });

    const { fields, replace, remove } = useFieldArray({
        control,
        name: "access",
    });

    const accessControl = watch("access") as
        | Array<IAccessControlAccess>
        | undefined;
    const role = watch("role");
    const controls = accessControl?.map((key) => {
        const control = accessControl?.find((value) => value.resource == key);

        return {
            resource: key.resource,
            create: control?.create ?? false,
            read: control?.read ?? false,
            update: control?.update ?? false,
            delete: control?.delete ?? false,
        };
    });

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        handleSubmit(
            () => {
                var values = getValues() as IAccessControl;
                if (values.role !== AccessControlRole.custom) {
                    delete values.access;
                }

                onFinish(values).catch((e) => {
                    // don't do anything
                });
            },
            () => false
        )(e);
    };

    const allItemsChecked = (operation: string) => {
        return accessControl?.every((control) => control[operation]);
    };

    const someItemsChecked = (operation: string) => {
        return accessControl?.some((control) => control[operation]);
    };

    const setAllItemsChecked = (checked: boolean, operation: string) => {
        const updatedControls = accessControl?.map((key) => {
            const control = accessControl?.find(
                (value) => value.resource == key
            );
            return {
                resource: key.resource,
                create: operation == "create" ? checked : key.create,
                read: operation == "read" ? checked : key.read,
                update: operation == "update" ? checked : key.update,
                delete: operation == "delete" ? checked : key.delete,
            };
        });
        if (updatedControls) {
            replace(updatedControls);
        }
    };

    const operationHeader = (operation: string) => {
        return (
            <HStack justify="center">
                <Checkbox
                    onChange={(e) =>
                        setAllItemsChecked(e.target.checked, operation)
                    }
                    isChecked={allItemsChecked(operation)}
                    isIndeterminate={
                        someItemsChecked(operation) &&
                        !allItemsChecked(operation)
                    }
                />
                <Text>{operation}</Text>
            </HStack>
        );
    };

    const form = () => {
        return (
            <VStack align="flex-start">
                <InputFormControl
                    name={"userId"}
                    label={"User ID"}
                    control={control}
                />
                <SelectFormControl
                    name="role"
                    label="Role"
                    control={control}
                    options={covertToOptionArray(
                        Object.values(AccessControlRole)
                    )}
                />
                {role == AccessControlRole.custom && (
                    <Box mt={5} w="100%">
                        <FormLabel>Access</FormLabel>
                        <TableContainer>
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th></Th>
                                        <Th>{operationHeader("create")}</Th>
                                        <Th>{operationHeader("read")}</Th>
                                        <Th>{operationHeader("update")}</Th>
                                        <Th>{operationHeader("delete")}</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {controls?.map((field, index) => (
                                        <Tr key={fields[index]?.id}>
                                            <Td>{field.resource}</Td>
                                            <Td textAlign="center">
                                                <CheckboxControl
                                                    name={`access.${index}.create`}
                                                    label={"Create"}
                                                    hideLabel={true}
                                                    control={control}
                                                    isRequired={false}
                                                    isDisabled={
                                                        role !==
                                                        AccessControlRole.custom
                                                    }
                                                />
                                            </Td>
                                            <Td textAlign="center">
                                                <CheckboxControl
                                                    name={`access.${index}.read`}
                                                    label={"Read"}
                                                    hideLabel={true}
                                                    control={control}
                                                    isRequired={false}
                                                    isDisabled={
                                                        role !==
                                                        AccessControlRole.custom
                                                    }
                                                />
                                            </Td>
                                            <Td textAlign="center">
                                                <CheckboxControl
                                                    name={`access.${index}.update`}
                                                    label={"Update"}
                                                    hideLabel={true}
                                                    control={control}
                                                    isRequired={false}
                                                    isDisabled={
                                                        role !==
                                                        AccessControlRole.custom
                                                    }
                                                />
                                            </Td>
                                            <Td textAlign="center">
                                                <CheckboxControl
                                                    name={`access.${index}.delete`}
                                                    label={"Delete"}
                                                    hideLabel={true}
                                                    control={control}
                                                    isRequired={false}
                                                    isDisabled={
                                                        role !==
                                                        AccessControlRole.custom
                                                    }
                                                />
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </Box>
                )}
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
        default:
            return <></>;
    }
};
