import { convertToDate, isValidDate } from "@bankingright-dashboard/utils";
import {
    Badge,
    BoxProps,
    Heading,
    HStack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { format } from "date-fns";
import React from "react";

export enum DetailItemType {
    text = "text",
    date = "date",
}

export interface DetailItemProps extends BoxProps {
    title: string;
    value?: string | number | boolean;
    type?: DetailItemType;
    showTime?: boolean;
    showVerification?: boolean;
}

export const DetailItem: React.FC<DetailItemProps> = ({
    title,
    value,
    type = DetailItemType.text,
    showTime = false,
    showVerification,
    ...props
}) => {
    var body = "";
    switch (type) {
        case DetailItemType.text:
            body = value?.toString() ?? "";
            break;
        case DetailItemType.date:
            if (value && !isValidDate(convertToDate(value as string))) {
                break;
            }
            body = format(
                convertToDate(value as string),
                showTime ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"
            );
            break;
    }

    return (
        <>
            {body && (
                <VStack align="flex-start" {...props}>
                    <Heading as="h5" size="sm">
                        {title}
                    </Heading>
                    <HStack spacing={3}>
                        <Text>{body}</Text>
                        {showVerification && (
                            <Badge
                                colorScheme={
                                    showVerification === true ? "green" : "red"
                                }
                            >
                                {showVerification === true
                                    ? "verified"
                                    : "not verified"}
                            </Badge>
                        )}
                    </HStack>
                </VStack>
            )}
        </>
    );
};
