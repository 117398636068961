import { ContentTriggerContentType } from "@bankingright-dashboard/enums";
import { IContentTrigger } from "@bankingright-dashboard/interfaces";
import {
    ButtonWithLabelProps,
    DetailItem,
    DetailItemType,
    Show,
} from "@bankingright-dashboard/ui";
import {
    Box,
    ButtonProps,
    Heading,
    IconButton,
    IconButtonProps,
    VStack,
} from "@chakra-ui/react";
import {
    useNavigation,
    useResourceParams,
    useShow,
    useTranslate,
} from "@refinedev/core";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import gfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import { FiUsers } from "react-icons/fi";
import { ChooseImageFormControl } from "@bankingright-dashboard/table";
import { ImageBox } from "@bankingright-dashboard/images";

export const ContentTriggerShow: React.FC = () => {
    const { query } = useShow<IContentTrigger>();
    const { data, isLoading } = query;
    const contentTrigger = data?.data;
    const translate = useTranslate();
    const { push } = useNavigation();
    const { id } = useResourceParams();

    const renderMessageBody = () => {
        switch (
            contentTrigger?.content?.contentType ??
            ContentTriggerContentType.plain
        ) {
            case ContentTriggerContentType.markdown:
                return (
                    <ReactMarkdown
                        remarkPlugins={[gfm]}
                        components={ChakraUIRenderer()}
                        children={contentTrigger?.content?.body ?? ""}
                        skipHtml
                    />
                );
            case ContentTriggerContentType.plain:
                return <Box>{contentTrigger?.content?.body}</Box>;
        }
    };

    const usersButton: ButtonWithLabelProps = {
        label: "Users",
        leftIcon: <FiUsers />,
        variant: "tertiary",
        "aria-label": "Users",
        onClick: () => {
            push(`/contenttriggers/show/${id}/users`);
        },
    };

    return (
        <VStack align="stretch" spacing={5}>
            <Show isLoading={isLoading} additionalButtons={[usersButton]}>
                <VStack align="flex-start" spacing={5}>
                    <VStack align="flex-start" spacing={3}>
                        <DetailItem title="ID" value={contentTrigger?.id} />
                        <DetailItem
                            title="Priority"
                            value={contentTrigger?.priority}
                        />
                        <DetailItem
                            title="Category"
                            value={contentTrigger?.category}
                        />
                        <DetailItem
                            title="Sub category"
                            value={contentTrigger?.subCategory}
                        />
                        <DetailItem
                            title="Location"
                            value={contentTrigger?.location}
                        />
                        <DetailItem
                            title="Dismissible"
                            value={contentTrigger?.dismissible}
                        />
                        <DetailItem
                            title="Is dismissed"
                            value={contentTrigger?.isDismissed}
                        />
                        <DetailItem
                            title="Start date"
                            value={contentTrigger?.startDate}
                            type={DetailItemType.date}
                            showTime={true}
                        />
                        <DetailItem
                            title="End date"
                            value={contentTrigger?.endDate}
                            type={DetailItemType.date}
                            showTime={true}
                        />
                        <DetailItem
                            title="Show count"
                            value={contentTrigger?.showCount}
                        />
                        <DetailItem
                            title="Max show count"
                            value={contentTrigger?.maxShowCount}
                        />
                        <DetailItem
                            title="Status"
                            value={contentTrigger?.status}
                        />
                    </VStack>

                    <VStack align="flex-start" spacing={3}>
                        <Heading fontSize="2xl">Content</Heading>0
                        <DetailItem
                            title="Title"
                            value={contentTrigger?.content?.title}
                        />
                        <VStack align="flex-start">
                            <Heading as="h5" size="sm">
                                Body
                            </Heading>
                            {renderMessageBody()}
                        </VStack>
                        <DetailItem
                            title="Button title"
                            value={contentTrigger?.content?.buttonTitle}
                        />
                        <DetailItem
                            title="Button URL"
                            value={contentTrigger?.content?.buttonUrl}
                        />
                        <DetailItem
                            title="Image URL"
                            value={contentTrigger?.content?.imageUrl}
                        />
                        {contentTrigger?.content?.imageId && (
                            <ImageBox
                                title="Image"
                                imageId={contentTrigger.content.imageId}
                            />
                        )}
                        <DetailItem
                            title="Base color"
                            value={contentTrigger?.content?.baseColor}
                        />
                        <DetailItem
                            title="Base color dark-mode"
                            value={contentTrigger?.content?.baseColorDark}
                        />
                    </VStack>

                    {contentTrigger?.statistics && (
                        <VStack align="flex-start" spacing={3}>
                            <Heading fontSize="2xl">Statistics</Heading>0
                            <DetailItem
                                title="Number of users"
                                value={contentTrigger?.statistics?.total}
                            />
                            <DetailItem
                                title="Number of times shown"
                                value={contentTrigger?.statistics?.shown}
                            />
                            <DetailItem
                                title="Number of times dismissed"
                                value={contentTrigger?.statistics?.dismissed}
                            />
                            <DetailItem
                                title="Number of times tapped"
                                value={contentTrigger?.statistics?.tapped}
                            />
                        </VStack>
                    )}
                </VStack>
            </Show>
        </VStack>
    );
};
