import {
    AuthBindings,
    CrudFilters,
    DataProvider,
    HttpError,
    LogicalFilter,
} from "@refinedev/core";
import {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
    InternalAxiosRequestConfig,
} from "axios";
import queryString from "query-string";
import { v4 as uuidv4 } from "uuid";

interface BankingRightDataProviderProps {
    apiUrl: string;
    httpClient: AxiosInstance;
    tokenKey?: string;
    authProvider: AuthBindings;
}

export const BankingRightDataProvider = ({
    apiUrl,
    httpClient,
    tokenKey = "bankingright-auth",
    authProvider,
}: BankingRightDataProviderProps): DataProvider => {
    httpClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            const customError: HttpError = {
                ...error,
                message:
                    error.response?.data?.userMessage ??
                    "Something went wrong!",
                statusCode: error.response?.status,
            };

            return Promise.reject(customError);
        }
    );

    httpClient.interceptors.request.use(
        // Here we can perform any function we'd like on the request
        (request: InternalAxiosRequestConfig) => {
            // Retrieve the token from local storage
            const token = localStorage.getItem(tokenKey);

            // Set the Authorization header if it exists
            if (token != null) {
                request.headers["Authorization"] = `Bearer ${token}`;
            }

            // set the BankingRight operation id
            request.headers["br-request-id"] = uuidv4();
            request.headers["br-timestamp"] = Math.floor(Date.now() / 1000);

            return request;
        }
    );

    httpClient.interceptors.response.use(
        (response: AxiosResponse<any, any>) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;

            if (error.response && error.response.status === 401) {
                if (!originalRequest._retry) {
                    console.log(
                        "The back-end returned 401, trying to renew the access token!"
                    );

                    originalRequest._retry = true;

                    const result = await authProvider.check();
                    if (result.authenticated) {
                        // Retrieve the new token
                        const newToken = localStorage.getItem(tokenKey);

                        // Update the Authorization header with the new token
                        if (newToken) {
                            originalRequest.headers[
                                "Authorization"
                            ] = `Bearer ${newToken}`;
                        }

                        // Retry the original request with the new token
                        return httpClient(originalRequest);
                    } else {
                        console.log(
                            "The back-end return 401, and the access token could not be refreshed, logging you out!"
                        );
                        authProvider.logout(null);
                    }
                } else {
                    console.log(
                        "The back-end return 401, and the access token could not be refreshed, logging you out!"
                    );
                    authProvider.logout(null);
                }
            }
            return Promise.reject(error);
        }
    );

    const generateFilter = (filters?: CrudFilters) => {
        const queryFilters: { [key: string]: string } = {};
        if (filters) {
            filters.map((filter) => {
                if (filter.operator !== "or") {
                    const { field, operator, value } = filter as LogicalFilter;
                    queryFilters[`${field}`] = value;
                }
            });
        }

        return queryFilters;
    };

    return {
        getList: async ({
            resource,
            pagination,
            filters,
            meta,
            hasPagination,
        }) => {
            const version = `v${meta?.version ?? "1"}`;

            if (meta?.id && meta?.parent && meta?.parentHasId) {
                meta.parent = `${meta.parent}/${meta.id}`;
            }

            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}`;

            // pagination
            const current = pagination?.current || 1;
            const pageSize = pagination?.pageSize || 10;

            // Handle filtering
            const queryFilters = generateFilter(filters);

            const query: {
                offset: number;
                num_results: number;
            } = {
                offset: (current - 1) * pageSize,
                num_results: pageSize,
            };

            // Handle pagination
            const { data, headers } = await httpClient.get(
                `${url}?${
                    hasPagination ? queryString.stringify(query) + "&" : ""
                }${queryString.stringify(queryFilters)}`
            );

            const key =
                Object.keys(data).find((key) => {
                    return (
                        key.toLowerCase() ==
                        resource.split("/").pop()?.toLowerCase()
                    );
                }) ?? resource;

            const { [key]: removedKey, ...rest } = data;

            return {
                data: data[key],
                ...rest,
            };
        },

        getMany: async ({ resource, ids, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}`;
            const { data } = await httpClient.get(
                `${url}?${queryString.stringify({ id: ids })}`
            );

            return {
                data,
            };
        },

        create: async ({ resource, variables, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}`;

            const { data } = await httpClient.post(url, variables);

            return {
                data,
            };
        },

        createMany: async ({ resource, variables, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}`;

            if (meta?.supportsBulkCreate) {
                const { data } = await httpClient.post(
                    `${url}/create/bulk`,
                    variables
                );
                return { data };
            } else {
                const response = await Promise.all(
                    variables.map(async (param) => {
                        const { data } = await httpClient.post(`${url}`, param);
                        return data;
                    })
                );

                return { data: response };
            }
        },

        update: async ({ resource, id, variables, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}/${id}`;

            const { data } = await httpClient.put(url, variables);

            return {
                data,
            };
        },

        updateMany: async ({ resource, ids, variables, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}`;

            const response = await Promise.all(
                ids.map(async (id) => {
                    const { data } = await httpClient.put(
                        `${url}/${id}`,
                        variables
                    );
                    return data;
                })
            );

            return { data: response };
        },

        getOne: async ({ resource, id, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const queryFilters = generateFilter(meta?.filters);
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}/${id}?${queryString.stringify(
                queryFilters
            )}`;

            const { data } = await httpClient.get(url);

            return {
                data,
            };
        },

        deleteOne: async ({ resource, id, variables, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}/${id}`;

            const { data } = await httpClient.delete(url);

            return {
                data,
            };
        },

        deleteMany: async ({ resource, ids, variables, meta }) => {
            const version = `v${meta?.version ?? "1"}`;
            const endpoint =
                meta?.parent != undefined
                    ? `${meta.parent}/${resource}`
                    : resource;
            const url = `${apiUrl}/${version}/${endpoint}`;

            if (meta?.supportsBulkDelete) {
                const { data } = await httpClient.post(`${url}/delete/bulk`, {
                    [resource]: ids,
                });
                return { data };
            } else {
                const response = await Promise.all(
                    ids.map(async (id) => {
                        const { data } = await httpClient.delete(
                            `${url}/${id}`
                        );
                        return data;
                    })
                );

                return { data: response };
            }
        },

        custom: async ({
            url,
            method,
            filters,
            payload,
            query,
            headers,
            meta,
        }) => {
            let requestUrl = `${url}?`;

            if (filters) {
                const filterQuery = generateFilter(filters);
                requestUrl = `${requestUrl}&${queryString.stringify(
                    filterQuery
                )}`;
            }

            if (query) {
                requestUrl = `${requestUrl}&${queryString.stringify(query)}`;
            }

            // if (headers) {
            //     httpClient.defaults.headers = {
            //         ...httpClient.defaults.headers,
            //         ...headers,
            //     };
            // }

            let config: AxiosRequestConfig = {
                responseType: meta?.responseType ?? "json",
            };

            let axiosResponse;
            switch (method) {
                case "put":
                case "post":
                case "patch":
                    axiosResponse = await httpClient[method](
                        url,
                        payload,
                        config
                    );
                    break;
                case "delete":
                    axiosResponse = await httpClient.delete(url, config);
                    break;
                default:
                    axiosResponse = await httpClient.get(requestUrl, config);
                    break;
            }

            const { data } = axiosResponse;

            return Promise.resolve({ data });
        },

        getApiUrl: () => {
            return apiUrl;
        },
    };

    // custom: async ({ url, method, filters, sort, payload, query, headers }) => {
    //     let requestUrl = `${url}?`;

    //     if (sort) {
    //         const generatedSort = generateSort(sort);
    //         if (generatedSort) {
    //             const { _sort, _order } = generatedSort;
    //             const sortQuery = {
    //                 _sort: _sort.join(","),
    //                 _order: _order.join(","),
    //             };
    //             requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
    //         }
    //     }

    //     if (filters) {
    //         const filterQuery = generateFilter(filters);
    //         requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    //     }

    //     if (query) {
    //         requestUrl = `${requestUrl}&${stringify(query)}`;
    //     }

    //     if (headers) {
    //         httpClient.defaults.headers = {
    //             ...httpClient.defaults.headers,
    //             ...headers,
    //         };
    //     }

    //     let axiosResponse;
    //     switch (method) {
    //         case "put":
    //         case "post":
    //         case "patch":
    //             axiosResponse = await httpClient[method](url, payload);
    //             break;
    //         case "delete":
    //             axiosResponse = await httpClient.delete(url);
    //             break;
    //         default:
    //             axiosResponse = await httpClient.get(requestUrl);
    //             break;
    //     }

    //     const { data } = axiosResponse;

    //     return Promise.resolve({ data });
    // },
};
