import {
    FilterType,
    ResourceAction,
    SecurityAuditStatus,
    SecurityAuditType,
} from "@bankingright-dashboard/enums";
import { ISecurityAudit } from "@bankingright-dashboard/interfaces";
import {
    FilterConfiguration,
    ResourceList,
} from "@bankingright-dashboard/table";
import {
    convertToDate,
    generateSecurityAuditStatusColorScheme,
} from "@bankingright-dashboard/utils";
import { Badge, HStack, Text } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const SecurityAuditList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<ISecurityAudit>[]>(
        () => [
            {
                id: "type",
                header: "Type",
                accessorKey: "type",
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.select,
                        options: Object.values(SecurityAuditType),
                    },
                },
            },
            {
                id: "date",
                header: "Date",
                accessorKey: "date",
                enableSorting: false,
                cell: function render({ getValue }) {
                    return (
                        <HStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </HStack>
                    );
                },
            },
            {
                id: "user_id",
                header: "User ID",
                accessorKey: "userId",
                enableColumnFilter: true,
            },
            {
                id: "endpoint",
                header: "Endpoint",
                accessorKey: "endpoint",
                enableColumnFilter: true,
            },
            {
                id: "status",
                header: "Status",
                accessorKey: "status",
                enableColumnFilter: true,
                cell: function render({ getValue }) {
                    const status = getValue() as SecurityAuditStatus;
                    return (
                        <Badge
                            colorScheme={generateSecurityAuditStatusColorScheme(
                                status
                            )}
                            variant="subtle"
                        >
                            {status}
                        </Badge>
                    );
                },
            },
        ],
        []
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "from_date",
            label: "From date",
            type: FilterType.date,
        },
    ];

    return (
        <ResourceList
            columns={columns}
            actions={[ResourceAction.show]}
            additionalFilters={additionalFilters}
            refineCoreProps={{ pagination: { pageSize: 25 } }}
            size="sm"
        />
    );
};
