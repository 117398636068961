import { IAccessControl } from "@bankingright-dashboard/interfaces";
import {
    AccessControlProvider,
    CanParams,
    CanReturnType,
} from "@refinedev/core";
import AsyncLock from "async-lock";
import { AxiosInstance } from "axios";

interface BankingRightAccessControlProviderProps {
    apiUrl: string;
    httpClient: AxiosInstance;
    cacheExpiresInMinutes?: number;
}

type AccessControlCache = {
    accessControl?: IAccessControl;
    expiresAt?: Date;
};

export const cacheTokenKey = "access-control-cache";

export const BankingRightAccessControlProvider = ({
    apiUrl,
    httpClient,
    cacheExpiresInMinutes = 30,
}: BankingRightAccessControlProviderProps): AccessControlProvider => {
    var lock = new AsyncLock();

    const mapAction = (action: string) => {
        if (action == "list" || action == "show") {
            return "read";
        } else if (action == "edit") {
            return "update";
        } else if (action == "clone") {
            return "create";
        } else {
            return action;
        }
    };

    return {
        can: async ({
            resource,
            action,
            params,
        }: CanParams): Promise<CanReturnType> => {
            return lock.acquire("cache", async function () {
                var cache: AccessControlCache = {};

                if (!resource) {
                    return { can: true };
                }

                var resourceName = resource;
                if (params?.resource?.meta?.accessControlName) {
                    resourceName = params.resource.meta.accessControlName;
                } else if (params?.resource?.meta?.parent) {
                    resourceName = params.resource.meta.parent + resource;
                }

                const localCache = localStorage.getItem(cacheTokenKey);
                if (localCache) {
                    cache = JSON.parse(localCache);
                }

                if (
                    cache.expiresAt == undefined ||
                    new Date(cache.expiresAt) < new Date()
                ) {
                    var accessControl: IAccessControl | undefined;
                    try {
                        const response = await httpClient.get<IAccessControl>(
                            `${apiUrl}/v1/accesscontrol/me`
                        );
                        accessControl = response.data;
                    } catch (error) {
                        console.log("Failed to retrieve access control", error);
                        return { can: false };
                    }

                    cache.accessControl = accessControl;
                    cache.expiresAt = new Date(
                        Date.now() + cacheExpiresInMinutes * 60 * 1000
                    );

                    localStorage.setItem(cacheTokenKey, JSON.stringify(cache));
                }

                if (resourceName && action && cache.accessControl) {
                    const resourceAccess = cache.accessControl.access?.find(
                        (value) =>
                            value.resource.toLowerCase() ==
                            resourceName.toLowerCase()
                    );
                    if (resourceAccess) {
                        return {
                            can:
                                resourceAccess[
                                    mapAction(
                                        action
                                    ) as keyof typeof resourceAccess
                                ] ?? false,
                        };
                    }
                }

                return { can: false };
            });
        },
        // Global settings
        options: {
            buttons: {
                enableAccessControl: true,
                // hide action buttons if not authorized.
                hideIfUnauthorized: false,
            },
        },
    };
};
