import { Image } from "@bankingright-dashboard/interfaces";
import { ImageCard } from "@bankingright-dashboard/ui";
import {
    convertToDate,
    generateImageSubTitle,
} from "@bankingright-dashboard/utils";
import {
    BoxProps,
    Heading,
    HStack,
    Spinner,
    Text,
    VStack
} from "@chakra-ui/react";
import { HttpError, useOne } from "@refinedev/core";

interface ImageBoxProps extends BoxProps {
    title: string;
    imageId: string;
}

export const ImageBox = ({ title, imageId, ...props }: ImageBoxProps) => {
    const { data, isLoading, isError } = useOne<Image, HttpError>({
        resource: "images",
        id: imageId,
    });

    const image = data?.data;

    return (
        <VStack align="flex-start" {...props}>
            <Heading as="h5" size="sm">
                {title}
            </Heading>
            <HStack spacing={3}>
                {isLoading && <Spinner />}
                {isError && <Text>Failed to load image</Text>}
                {image && (
                    <ImageCard
                        name={image.filename}
                        url={image.directUrl}
                        fallbackUrl="https://via.placeholder.com/300"
                        subTitle={generateImageSubTitle(image)}
                        date={convertToDate(image.dateModified)}
                    />
                )}
            </HStack>
        </VStack>
    );
};
