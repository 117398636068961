import { FormType } from "@bankingright-dashboard/enums";

import { ContentTriggerForm } from "./form";

interface ContentTriggerCloneProps {
    suggestedColors?: string[];
    suggestedColorsDark?: string[];
}

export const ContentTriggerClone = ({
    suggestedColors,
    suggestedColorsDark,
}: ContentTriggerCloneProps) => {    
    
    return (
        <ContentTriggerForm
            type={FormType.clone}
            suggestedColors={suggestedColors}
            suggestedColorsDark={suggestedColorsDark}
        />
    );
};
