export enum UserAddressType {
    primary = "primary",
    contact = "contact",
    other = "other",
}

export enum OnboardingStatus {
    light = "light",
    full = "full",
    none = "none",
    notset = "notset",
}

export enum UserStatus {
    active = "active",
    blocked = "blocked",
}

export enum AppConfigurationType {
    string = "string",
    boolean = "boolean",
    integer = "integer",
    enum = "enum",
    multi = "multi",
}

export enum FormType {
    edit = "edit",
    create = "create",
    clone = "clone",
}

export enum MessageBodyType {
    html = "html",
    plain = "plain",
    markdown = "markdown",
}

export enum MessageType {
    message = "message",
    document = "document",
}

export enum MessageDirection {
    incoming = "incoming",
    outgoing = "outgoing",
}

export enum MessageAction {
    delete = "delete",
}

export enum MessageStatus {
    active = "active",
    draft = "draft",
}

export enum ResourceAction {
    show = "show",
    delete = "delete",
    edit = "edit",
    import = "import",
    export = "export",
    clone = "clone",
}

export enum Verb {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
    PATCH = "PATCH",
}

export enum LogSource {
    api = "api",
    management = "management",
}

export enum LogType {
    request = "request",
    exception = "exception",
    trace = "trace",
    dependency = "dependency",
}

export enum LogLevel {
    trace = "trace",
    debug = "debug",
    information = "information",
    warning = "warning",
    error = "error",
    critical = "critical",
}

export enum FilterType {
    input = "input",
    select = "select",
    date = "date",
    dateTime = "datetime",
}

export enum DevicePlatform {
    ios = "ios",
    android = "android",
}

export enum DeviceStatus {
    pending = "pending",
    confirmed = "confirmed",
    blocked = "blocked",
}

export enum SecurityAuditType {
    authenticate = "authenticate",
    confirm = "confirm",
    login = "login",
    loginrequest = "loginrequest",
    loginrequestverify = "loginrequestverify",
    challenge = "challenge",
    refresh = "refresh",
    payment = "payment",
    verificationcode = "verificationcode",
    verifyotp = "verifyotp",
    verifyotpmangement = "verifyotpmangement",
    logout = "logout",
    disconnect = "disconnect",
}

export enum SecurityAuditStatus {
    success = "success",
    failed = "failed",
}

export enum AccessControlRole {
    administrator = "administrator",
    contributor = "contributor",
    editor = "editor",
    reader = "reader",
    custom = "custom",
    none = "none",
}

export enum AccessControlResource {
    users = "users",
    userssegments = "userssegments",
    devices = "devices",
    messages = "messages",
    pushnotifications = "pushnotifications",
    calltoaction = "calltoaction",
    appconfiguration = "appconfiguration",
    contenttriggers = "contenttriggers",
    onboardings = "onboardings",
    branchio = "branchio",
    fileimport = "fileimport",
    verificationcode = "verificationcode",
    wealthforecastconfiguration = "wealthforecastconfiguration",
    securityaudits = "securityaudits",
    positionsenrichments = "positionsenrichments",
    signingtasksapprovalrequests = "signingtaskapprovalrequests",
    logs = "logs",
    statistics = "statistics",
    localizations = "localizations",
    testusers = "testusers",
    accesscontrol = "accesscontrol",
    health = "health",
    processes = "processes",
    images = "images",
    communication = "communication",
    accessaudits = "accessaudits",
}

export enum PositionEnrichmentType {
    url = "url",
    attachment = "attachment",
}

export enum WealthForecastScenarioType {
    good = "good",
    expected = "expected",
    bad = "bad",
}

export enum WealthForecastCategory {
    cash = "cash",
    governmentbonds = "governmentbonds",
    highyieldcorporatebonds = "highyieldcorporatebonds",
    investmentgradecorporatebonds = "investmentgradecorporatebonds",
    developedmarketequity = "developedmarketequity",
    emergingmarketequity = "emergingmarketequity",
    realestate = "realestate",
    commodities = "commodities",
    alternatives = "alternatives",
    privateequity = "privateequity",
    passionassets = "passionassets",
    liabilities = "liabilities",
}

export enum HealthChecksStatus {
    healthy = "Healthy",
    degraded = "Degraded",
    unhealthy = "Unhealthy",
}

export enum SignItemType {
    payment = "payment",
    ideal = "ideal",
    task = "task",
    loginapproval = "loginapproval",
}

export enum PaymentType {
    domestic = "domestic",
    sepa = "sepa",
    international = "international",
    bulk = "bulk",
    announceddirectdebit = "announceddirectdebit",
}

export enum RecurrencePeriod {
    yearly = "yearly",
    halfyearly = "halfyearly",
    quarterly = "quarterly",
    everytwomonths = "everytwomonths",
    monthly = "monthly",
    everytwoweeks = "everytwoweeks",
    weekly = "weekly",
    daily = "daily",
    once = "once",
}

export enum FeeChargeType {
    sender = "sender",
    shared = "shared",
    beneficiary = "beneficiary",
}

export enum SigningTaskApprovalStatus {
    pending = "pending",
    approved = "approved",
    rejected = "rejected",
}

export enum SignItemAction {
    viewDetails = "viewDetails",
    approve = "approve",
    reject = "reject",
    delete = "delete",
}

export enum ContentTriggerCategory {
    infobannerimage = "infobannerimage",
    infobannerminimal = "infobannerminimal",
    infobannercompact = "infobannercompact",
    infobanner = "infobanner",
}

export enum ContentTriggerLocation {
    accountoverview = "accountoverview",
    transactionoverview = "transactionoverview",
    moremenu = "moremenu",
    inbox = "inbox",
}

export enum ContentTriggerContentType {
    plain = "plain",
    markdown = "markdown",
}
export enum ContentTriggerStatus {
    active = "active",
    draft = "draft",
}

export enum ImageCategory {
    account = "account",
    transaction = "transaction",
    paymentrequest = "paymentrequest",
    user = "user",
    passport = "passport",
    other = "other",
}

export enum ResourceListStyle {
    table = "table",
    grid = "grid",
}

export enum NotificationType {
    email = "email",
    sms = "sms",
    push = "push",
}

export enum AccessAuditOperationType {
    create = "create",
    update = "update",
    delete = "delete",
}
