import {
    AccessControlResource,
    NotificationType,
} from "@bankingright-dashboard/enums";
import { EmailTemplate, IUser } from "@bankingright-dashboard/interfaces";
import {
    InputFormControl,
    InputFormType,
    SelectFormControl,
} from "@bankingright-dashboard/table";
import {
    Button,
    ButtonProps,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import {
    UilEnvelopeEdit,
    UilMobileAndroidAlt,
    UilMobileVibrate,
} from "@iconscout/react-unicons";
import {
    useApiUrl,
    useCan,
    useCustomMutation,
    useGo,
    useNotification,
} from "@refinedev/core";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import React, { useEffect, useMemo } from "react";

interface SendNotificationProps extends ButtonProps {
    user: IUser;
    notificationType: NotificationType;
    icon?: React.ReactElement;
    templates?: EmailTemplate[];
}

type SendNotificationFormValues = {
    subject: string;
    message: string;
    template?: string;
    attributes?: Array<ArgumentData>;
};

type ArgumentData = {
    name: string;
    key: string;
    value: string;
    type: InputFormType;
};

export const SendNotification: React.FC<SendNotificationProps> = ({
    user,
    notificationType,
    icon,
    templates,
    ...props
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { mutate, isLoading } = useCustomMutation();
    const go = useGo();
    const { open } = useNotification();
    const apiUrl = useApiUrl();
    const url = `${apiUrl}/v1/${notificationType.toString()}/send`;
    const { data: canCreatePushnotification } = useCan({
        resource: AccessControlResource.pushnotifications,
        action: "create",
    });
    const { data: canCreateCommunication } = useCan({
        resource: AccessControlResource.communication,
        action: "create",
    });

    const { control, handleSubmit, reset, watch } =
        useForm<SendNotificationFormValues>({
            defaultValues: {
                template: "none",
                subject: undefined,
                message: undefined,
                attributes: undefined,
            },
        });

    const { fields, replace, remove } = useFieldArray({
        control,
        name: "attributes",
    });

    const { template } = watch();

    const defaultIcon = () => {
        switch (notificationType) {
            case NotificationType.email:
                return <UilEnvelopeEdit size={18} />;
            case NotificationType.sms:
                return <UilMobileAndroidAlt size={18} />;
            case NotificationType.push:
                return <UilMobileVibrate size={18} />;
            default:
                return <UilEnvelopeEdit size={18} />;
        }
    };

    useEffect(() => {
        reset({
            template: template,
        });
        const attributes = templates?.find((t) => t.id == template)?.attributes;
        if (attributes) {
            const values = attributes?.map((attribute) => ({
                name: attribute.name,
                key: attribute.key,
                value: "",
                type: attribute.type,
            }));
            remove();
            replace(values);
        }
    }, [template]);

    const onSubmit = async (input: SendNotificationFormValues) => {
        // we first clean up the attributes
        const attributes = input.attributes?.map((attribute) => ({
            key: attribute.key,
            value: attribute.value,
        }));

        mutate(
            {
                url: url,
                method: "post",
                values: {
                    userIds: [user.id],
                    subject: input.subject,
                    body: input.message,
                    templateId: template == "none" ? undefined : input.template,
                    attributes: attributes,
                },
            },
            {
                onSuccess: () => {
                    open?.({
                        type: "success",
                        message: `Send ${notificationType} successfully.`,
                        description: `The ${notificationType} has been successfully sent to the user.`,
                    });
                    onCloseModal();
                },
            }
        );
    };

    const isButtonDisabled = () => {
        if (notificationType == NotificationType.push) {
            return !canCreatePushnotification?.can;
        } else {
            return !canCreateCommunication?.can;
        }
    };

    const onClick = () => {
        if (notificationType == NotificationType.push) {
            go({
                to: {
                    resource: "pushnotifications",
                    action: "create",
                },
                query: {
                    userId: user.id,
                },
            });
        } else {
            onOpen();
        }
    };

    const onCloseModal = () => {
        onClose();
        reset({ template: "none" });
        remove();
    };

    var emailTemplatesOptions = templates?.map((template) => ({
        value: template.id,
        label: template.name,
    }));
    emailTemplatesOptions?.unshift({ value: "none", label: "None" });

    const renderInput = () => {
        if (template == "none") {
            return (
                <>
                    {notificationType == NotificationType.email && (
                        <InputFormControl
                            control={control}
                            name="subject"
                            label="Subject"
                            isRequired={true}
                            rules={{
                                maxLength: {
                                    value: 988,
                                    message:
                                        "Subject must be less than 988 characters.",
                                },
                            }}
                        />
                    )}
                    <InputFormControl
                        control={control}
                        type={
                            notificationType == NotificationType.sms
                                ? InputFormType.input
                                : InputFormType.textarea
                        }
                        name="message"
                        label="Message"
                        isRequired={true}
                        rules={{
                            maxLength: {
                                value:
                                    notificationType == NotificationType.sms
                                        ? 160
                                        : 5000,
                                message: `Message must be less than ${
                                    notificationType == NotificationType.sms
                                        ? 160
                                        : 5000
                                } characters.`,
                            },
                        }}
                    />
                </>
            );
        } else {
            return fields.map((field, index) => (
                <InputFormControl
                    key={field.key}
                    control={control}
                    name={`attributes.${index}.value`}
                    label={field.name}
                    type={field.type}
                    isRequired={true}
                />
            ));
        }
    };

    return (
        <>
            <Button
                variant="tertiary"
                leftIcon={icon ?? defaultIcon()}
                onClick={onClick}
                aria-label={`Send ${notificationType}`}
                title={
                    isButtonDisabled()
                        ? `You don't have permission to send ${notificationType}.`
                        : undefined
                }
                isDisabled={isButtonDisabled()}
                {...props}
            >
                {`Send ${notificationType}`}
            </Button>
            <Modal
                isOpen={isOpen}
                closeOnOverlayClick={false}
                onClose={onCloseModal}
            >
                <ModalOverlay />
                <ModalContent
                    maxW={
                        notificationType == NotificationType.email
                            ? "50%"
                            : "30%"
                    }
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <ModalHeader>Send {notificationType}</ModalHeader>
                        <ModalCloseButton isDisabled={isLoading} />
                        <ModalBody>
                            <VStack align="flex-start" spacing={3}>
                                {emailTemplatesOptions &&
                                    notificationType ==
                                        NotificationType.email && (
                                        <SelectFormControl
                                            control={control}
                                            name="template"
                                            label="Template"
                                            options={emailTemplatesOptions}
                                        />
                                    )}
                                {renderInput()}
                            </VStack>
                        </ModalBody>

                        <ModalFooter>
                            <Button
                                variant="primary"
                                mr={3}
                                type="submit"
                                isLoading={isLoading}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="ghost"
                                onClick={onCloseModal}
                                isDisabled={isLoading}
                            >
                                Cancel
                            </Button>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        </>
    );
};
