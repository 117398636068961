import { useForm } from "@refinedev/react-hook-form";
import {
    DatePickerFormControl,
    InputFormControl,
    PasswordInputFormControl,
    SelectFormControl,
    SwitchFormControl,
} from "@bankingright-dashboard/table";
import {
    FormType,
    OnboardingStatus,
    UserStatus,
} from "@bankingright-dashboard/enums";
import { ICallToAction, IUser } from "@bankingright-dashboard/interfaces";
import { useTranslate } from "@refinedev/core";
import { Create, Edit } from "@bankingright-dashboard/ui";
import {
    FormControl,
    FormLabel,
    Select,
    FormErrorMessage,
    Checkbox,
    VStack,
} from "@chakra-ui/react";
import {
    covertToOptionArray,
    removeEmptyValues,
} from "@bankingright-dashboard/utils";
import { FieldValues } from "react-hook-form";

interface UserFormProps {
    type: FormType;
}

export const UserForm = ({ type }: UserFormProps) => {
    const translate = useTranslate();

    const {
        refineCore: { formLoading, queryResult, onFinish },
        saveButtonProps,
        register,
        control,
        formState: { errors },
        resetField,
        handleSubmit,
    } = useForm<IUser>();

    // Override the default save operation
    saveButtonProps.onClick = (e: any) => {
        e.preventDefault();

        // Clean up the data that we are submitting
        handleSubmit(
            (data) => {
                delete data.repeatPassword;
                onFinish(removeEmptyValues(data)).catch((e) => {
                    // don't do anything
                });
            },
            () => false
        )(e);
    };

    const form = () => {
        return (
            <VStack>
                {type === FormType.create && (
                    <>
                        <InputFormControl
                            name={"userName"}
                            label="Username"
                            control={control}
                        />

                        <PasswordInputFormControl
                            control={control}
                            name={"password"}
                            label="Password"
                        />
                    </>
                )}
                <SelectFormControl
                    control={control}
                    name="title"
                    label={"Title"}
                    options={covertToOptionArray(["Mr.", "Mrs"])}
                    selectedOptionStyle="check"
                    isRequired={false}
                />
                <InputFormControl
                    name={"initials"}
                    label="Initials"
                    control={control}
                    isRequired={false}
                />
                <InputFormControl
                    name={"firstName"}
                    label="First name"
                    control={control}
                />
                <InputFormControl
                    name={"lastName"}
                    label="Last name"
                    control={control}
                />
                <DatePickerFormControl
                    control={control}
                    id="dateOfBirth"
                    name="dateOfBirth"
                    label="Date of Birth"
                    showTime={false}
                    isRequired={false}
                />
                <InputFormControl
                    name={"email"}
                    label="Email"
                    control={control}
                    isRequired={false}
                />
                <SwitchFormControl
                    name={"emailVerified"}
                    label="Email Verified?"
                    control={control}
                    isRequired={false}
                />
                <InputFormControl
                    name={"mobilePhone"}
                    label="Mobile Phone"
                    control={control}
                    isRequired={false}
                />
                <SwitchFormControl
                    name={"mobilePhoneVerified"}
                    label="Mobile Phone Verified?"
                    control={control}
                    isRequired={false}
                />
                <InputFormControl
                    name={"Phone"}
                    label="phone"
                    control={control}
                    isRequired={false}
                />
                <SelectFormControl
                    control={control}
                    name="status"
                    label={"Status"}
                    options={covertToOptionArray([
                        UserStatus.active,
                        UserStatus.blocked,
                    ])}
                    selectedOptionStyle="check"
                    defaultValue={UserStatus.active}
                />
                <SelectFormControl
                    control={control}
                    name="onboardingStatus"
                    label={"Onboarding Status"}
                    options={covertToOptionArray([
                        OnboardingStatus.full,
                        OnboardingStatus.light,
                        OnboardingStatus.none,
                        OnboardingStatus.notset,
                    ])}
                    selectedOptionStyle="check"
                    isRequired={true}
                />
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
        default:
            return <></>;
    }
};
