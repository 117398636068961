import React, { useContext } from "react";
import {
    useCan,
    useNavigation,
    useTranslate,
    useResource,
    AccessControlContext,
    useCloneButton,
} from "@refinedev/core";
import { Button, ButtonProps, IconButton } from "@chakra-ui/react";
import { FiCopy, FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import type { RefineCloneButtonProps } from "@refinedev/ui-types";

interface CloneButtonProps extends RefineCloneButtonProps<ButtonProps> {
    icon?: React.ReactElement;
}

export const CloneButton = ({
    icon = <FiCopy />,
    resource: resourceNameFromProps,
    hideText = false,
    recordItemId,
    accessControl,
    meta,
    children,
    onClick,
    ...props
}: CloneButtonProps) => {
    const { to, label, title, hidden, disabled, canAccess, LinkComponent } =
        useCloneButton({
            resource: resourceNameFromProps,
            id: recordItemId,
            accessControl,
            meta,
        });

    if (hidden) return null;

    return (
        <LinkComponent
            to={to}
            replace={false}            
            onClick={(e: React.PointerEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                if (disabled) {
                    e.preventDefault();
                    return;
                }
                if (onClick) {
                    e.preventDefault();
                    onClick(e);
                }
            }}
        >
            {hideText ? (
                <IconButton
                    variant="action"
                    aria-label={label}
                    title={title}
                    isDisabled={disabled}
                    icon={icon}
                    onClick={onClick}
                    w="100%"
                    {...props}
                />
            ) : (
                <Button
                    variant="action"
                    isDisabled={disabled}
                    leftIcon={icon}
                    title={title}
                    onClick={onClick}
                    w="100%"
                    {...props}
                >
                    {children ?? label}
                </Button>
            )}
        </LinkComponent>
    );
};
