import {
    DevicePlatform,
    FilterType,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { ICallToAction } from "@bankingright-dashboard/interfaces";
import { ResourceList } from "@bankingright-dashboard/table";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const CallToActionList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<ICallToAction>[]>(
        () => [
            {
                id: "platform",
                header: translate("pages.calltoaction.platform", "Platform"),
                accessorKey: "platform",
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.select,
                        options: Object.values(DevicePlatform),
                    },
                },
            },
            {
                id: "app_version",
                header: translate(
                    "pages.calltoaction.appVersion",
                    "App Version"
                ),
                accessorKey: "appVersion",
                enableColumnFilter: true,
            },
            {
                id: "platform_version",
                header: translate(
                    "pages.calltoaction.platformVersion",
                    "Platform Version"
                ),
                accessorKey: "platformVersion",
                enableColumnFilter: true,
            },
            {
                id: "blocking",
                header: translate("pages.calltoaction.blocking", "Blocking"),
                accessorKey: "blocking",
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.select,
                        options: ["true", "false"],
                    },
                },
            },
        ],
        []
    );

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.show,
                ResourceAction.edit,
                ResourceAction.delete,
                ResourceAction.clone,
            ]}
            hasSelection={true}
        />
    );
};
