import {
    ContentTriggerStatus,
    FilterType,
    ResourceAction,
} from "@bankingright-dashboard/enums";
import { IContentTrigger } from "@bankingright-dashboard/interfaces";
import {
    FilterConfiguration,
    ResourceList,
} from "@bankingright-dashboard/table";
import { StatusButton } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import { VStack, Text, Badge } from "@chakra-ui/react";
import { useTranslate } from "@refinedev/core";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";

export const ContentTriggerList: React.FC = () => {
    const translate = useTranslate();

    const columns = React.useMemo<ColumnDef<IContentTrigger>[]>(
        () => [
            {
                id: "title",
                header: "Title",
                accessorKey: "content.title",
                meta: {
                    width: "50%",
                },
            },
            {
                id: "location",
                header: "Location",
                accessorKey: "location",
            },
            {
                id: "startDate",
                header: "Start Date",
                accessorKey: "startDate",
                cell: function render({ getValue }) {
                    return (
                        <VStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>
                    );
                },
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.date,
                    },
                },
            },
            {
                id: "endDate",
                header: "End Date",
                accessorKey: "endDate",
                cell: function render({ getValue }) {
                    return (
                        <VStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>
                    );
                },
                enableColumnFilter: true,
                meta: {
                    filter: {
                        type: FilterType.date,
                    },
                },
            },
            {
                id: "status",
                header: "Status",
                accessorKey: "status",
                cell: function render({ row, getValue }) {
                    const value =
                        (getValue() as ContentTriggerStatus) ?? "active";
                    return (
                        <StatusButton
                            recordItemId={row.original.id}
                            status={value}
                            startDate={convertToDate(row.original.startDate)}
                            endDate={convertToDate(row.original.endDate)}
                        />
                    );
                },
            },
        ],
        []
    );

    const additionalFilters: Array<FilterConfiguration> = [
        {
            id: "id",
            label: "ID",
            type: FilterType.input,
        },
        {
            id: "user_id",
            label: "User ID",
            type: FilterType.input,
        },
    ];

    return (
        <ResourceList
            columns={columns}
            actions={[
                ResourceAction.edit,
                ResourceAction.delete,
                ResourceAction.show,
                ResourceAction.clone,
            ]}
            additionalFilters={additionalFilters}
            hasSelection={true}
        />
    );
};
