import { HealthChecksStatus } from "@bankingright-dashboard/enums";
import { HealthChecks } from "@bankingright-dashboard/interfaces";
import {
    LoadingIndicator,
    RefreshButton,
    Timeline,
    TimelineData,
} from "@bankingright-dashboard/ui";
import { normalize } from "@bankingright-dashboard/utils";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerHeader,
    DrawerOverlay,
    Flex,
    HStack,
    Heading,
    IconButton,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useDisclosure
} from "@chakra-ui/react";
import { useCustom, useResource, useTranslate } from "@refinedev/core";
import { parseISO } from "date-fns";
import React, { useState } from "react";
import {
    FiAlertOctagon,
    FiAlertTriangle,
    FiCheck,
    FiInfo,
} from "react-icons/fi";

const managementApiUrl = process.env.REACT_APP_API_URL!;

export const HealthChecksShow: React.FC = () => {
    const translate = useTranslate();
    const { resource } = useResource();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [healthCheckDetailsShown, setHealthCheckDetailsShown] = useState("");

    const healthChecksApi = useCustom<Array<HealthChecks>>({
        url: `${managementApiUrl}/healthchecks-api`,
        method: "get",
    });

    const refresh = async () => {
        await healthChecksApi.refetch();
    };

    const healthChecks = healthChecksApi.data?.data?.[0];
    const isLoading = healthChecksApi.isLoading || healthChecksApi.isFetching;

    const tags = (tags: string[]) => {
        return (
            <HStack justify="center">
                {tags?.map((tag) => (
                    <Badge key={tag} colorScheme="blue" mr={1}>
                        {tag}
                    </Badge>
                ))}
            </HStack>
        );
    };

    const status = (status: HealthChecksStatus) => {
        switch (status) {
            case HealthChecksStatus.healthy:
                return <Badge colorScheme="green">{status}</Badge>;
            case HealthChecksStatus.degraded:
                return <Badge colorScheme="yellow">{status}</Badge>;
            case HealthChecksStatus.unhealthy:
                return <Badge colorScheme="red">{status}</Badge>;
            default:
                return <Badge colorScheme="gray">Unknown</Badge>;
        }
    };

    const history = (healthChecksName: string) => {
        const icon = (status: HealthChecksStatus) => {
            switch (status) {
                case HealthChecksStatus.healthy:
                    return <FiCheck size={20} color="white" />;
                case HealthChecksStatus.degraded:
                    return <FiAlertTriangle size={20} color="white" />;
                case HealthChecksStatus.unhealthy:
                    return <FiAlertOctagon size={20} color="white" />;
            }
        };

        const color = (status: HealthChecksStatus) => {
            switch (status) {
                case HealthChecksStatus.healthy:
                    return "alert.icon-success";
                case HealthChecksStatus.degraded:
                    return "alert.icon-warning";
                case HealthChecksStatus.unhealthy:
                    return "alert.icon-error";
            }
        };

        const data: TimelineData[] =
            healthChecks?.history
                .filter((check) => {
                    return check.name == healthChecksName;
                })
                .map((item, index) => {
                    return {
                        id: index,
                        date: parseISO(item.on).toLocaleString(),
                        title: item.status,
                        description: item.description,
                        status: item.status,
                        icon: icon(item.status),
                        color: color(item.status),
                    };
                }) ?? [];

        return <Timeline title="History" data={data} />;
    };

    const table = () => {
        return (
            <>
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th textAlign="center">
                                    <Text>Name</Text>
                                </Th>
                                <Th textAlign="center">
                                    <Text>Tags</Text>
                                </Th>
                                <Th textAlign="center">
                                    <Text>Status</Text>
                                </Th>
                                <Th textAlign="center">
                                    <Text>Description</Text>
                                </Th>
                                <Th textAlign="center">
                                    <Text>Duration</Text>
                                </Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {healthChecks?.entries?.map((entry, index) => (
                                <Tr key={entry.id}>
                                    <Td>{entry.name}</Td>
                                    <Td>{tags(entry.tags)}</Td>
                                    <Td textAlign="center">{status(entry.status)}</Td>
                                    <Td>{entry.description}</Td>
                                    <Td>{entry.duration}</Td>
                                    <Td>
                                        <IconButton
                                            variant="ghost"
                                            aria-label="Details"
                                            icon={<FiInfo size={18} />}
                                            disabled={isLoading}
                                            onClick={() => {
                                                setHealthCheckDetailsShown(
                                                    entry.name
                                                );
                                                onOpen();
                                            }}
                                        />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
                <Drawer onClose={onClose} isOpen={isOpen} size="xl">
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton />
                        <DrawerHeader>{healthCheckDetailsShown}</DrawerHeader>
                        <DrawerBody>
                            {history(healthCheckDetailsShown)}
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            </>
        );
    };

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <Flex>
                    <Heading variant="title-bold">
                        {translate(
                            `${normalize(resource!.name)}.titles.list`,
                            resource!.name
                        )}
                    </Heading>
                    <Spacer />
                    <HStack spacing={5}>
                        {healthChecks && healthChecks.lastExecuted && (
                            <HStack>
                                <Text variant="body">Last checked:</Text>
                                <Text  variant="body">
                                    {parseISO(
                                        healthChecks.lastExecuted
                                    ).toLocaleString()}
                                </Text>
                            </HStack>
                        )}
                        <RefreshButton onClick={refresh} />
                    </HStack>
                </Flex>
            </CardHeader>

            <CardBody>{healthChecks && table()}</CardBody>

            <LoadingIndicator isLoading={isLoading} />
        </Card>
    );
};
