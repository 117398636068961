import { ILog } from "@bankingright-dashboard/interfaces";
import { useParams, useSearchParams } from "react-router-dom";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";

import { LogType, ResourceAction } from "@bankingright-dashboard/enums";
import { BackButton, LoadingIndicator } from "@bankingright-dashboard/ui";
import { convertToDate } from "@bankingright-dashboard/utils";
import {
    Badge,
    Box,
    Card,
    CardBody,
    CardHeader,
    Flex,
    HStack,
    Heading,
    List,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useColorModeValue,
} from "@chakra-ui/react";
import { useList, useNavigation } from "@refinedev/core";

import { ColumnDef } from "@tanstack/react-table";
import {
    ResourceList,
    ResourceListLoadingState,
} from "@bankingright-dashboard/table";

import React, { useEffect, useState } from "react";

export const OperationDetails: React.FC = () => {
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const [loadingState, setLoadingState] =
        useState<ResourceListLoadingState<ILog>>();
    const [chartData, setChartDate] = useState<any[]>([]);
    const colors = ["blue.200", "blue.400", "blue.600", "blue.800", "blue.900"];

    const columns = React.useMemo<ColumnDef<ILog>[]>(
        () => [
            {
                id: "id",
                header: "",
                accessorKey: "id",
                cell: function render({ row, getValue }) {
                    const id = getValue() as string;
                    return (
                        <Box
                            boxSize={5}
                            background={
                                chartData.find((data) => id == data.id)
                                    ?.customFill ?? "transparent"
                            }
                        />
                    );
                },
                meta: {
                    width: "25px",
                },
            },
            {
                id: "type",
                header: "Type",
                accessorKey: "type",
                cell: function render({ getValue }) {
                    const type = getValue() as string;
                    switch (type) {
                        case "request":
                            return <Badge colorScheme="blue">{type}</Badge>;
                        case "exception":
                            return <Badge colorScheme="red">{type}</Badge>;
                        default:
                            return <Badge>{type}</Badge>;
                    }
                },
            },
            {
                id: "date",
                header: "Date",
                accessorKey: "date",
                cell: function render({ row, getValue }) {
                    return (
                        <VStack align="flex-start">
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleDateString()}
                            </Text>
                            <Text>
                                {convertToDate(
                                    getValue() as string
                                ).toLocaleTimeString()}
                            </Text>
                        </VStack>
                    );
                },
            },
            {
                id: "endpoint",
                header: "Name",
                accessorKey: "endpoint",
                meta: {
                    width: "50%",
                },
            },
            {
                id: "resultCode",
                header: "Status",
                accessorKey: "resultCode",
                cell: function render({ row, getValue }) {
                    const resultCode = getValue() as number;
                    if (resultCode < 200 || resultCode >= 300) {
                        return (
                            <Badge colorScheme="red" variant="subtle">
                                {resultCode}
                            </Badge>
                        );
                    } else {
                        return (
                            <Badge colorScheme="green" variant="subtle">
                                {resultCode}
                            </Badge>
                        );
                    }
                },
            },
            {
                id: "duration",
                header: "Duration",
                accessorKey: "duration",
                cell: function render({ row, getValue }) {
                    const duration = getValue() as number;
                    return (
                        <HStack>
                            <Text>{duration}</Text>
                            {duration && <Text>ms</Text>}
                        </HStack>
                    );
                },
            },
        ],
        [chartData]
    );

    useEffect(() => {
        const generateChartData = (data: ILog[]) => {
            const sortedLogs =
                data.sort((value1, value2) => +value1.date - +value2.date) ??
                [];

            return sortedLogs
                ?.filter(
                    (value) =>
                        value.type == LogType.request ||
                        value.type == LogType.dependency
                )
                .map((value, index) => ({
                    name: value.name,
                    duration: value.duration,
                    idle: +value.date * 1000 - +sortedLogs[0].date * 1000,
                    id: value.id,
                    customFill: `var(--chakra-colors-${colors[
                        index % colors.length
                    ].replace(".", "-")})`,
                }));
        };
        if (loadingState?.data) {
            setChartDate(generateChartData(loadingState.data.data));
        }
    }, [loadingState]);

    return (
        <Card bg="defaults.bg-light">
            <CardHeader>
                <Flex>
                    <HStack spacing={2}>
                        <BackButton />
                        <Heading size="lg">Operation details</Heading>
                    </HStack>
                    <Spacer />
                    {/* <RefreshButton /> */}
                </Flex>
            </CardHeader>
            <CardBody minH="800px">
                <Box>
                    <Chart chartData={chartData} />
                    <ResourceList
                        resourceName="logs"
                        columns={columns}
                        refineCoreProps={{
                            resource: "logs",
                            filters: {
                                permanent: [
                                    {
                                        field: "operation_id",
                                        operator: "eq",
                                        value: id,
                                    },
                                    {
                                        field: "source",
                                        operator: "eq",
                                        value: searchParams.get("source"),
                                    },
                                ],
                            },
                            pagination: {
                                mode: "off",
                            },
                        }}
                        canRefresh={false}
                        onLoadingStateChange={setLoadingState}
                        showHeader={false}
                        actions={[ResourceAction.show]}
                    />
                </Box>
            </CardBody>
        </Card>
    );
};

const Chart: React.FC<{ chartData: any[] }> = ({ chartData }) => {
    return (
        <Flex
            direction="column"
            align="center"
            h={`${100 + chartData.length * 50}px`}
            mb={5}
        >
            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    layout="vertical"
                    data={chartData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis type="number" />
                    <YAxis dataKey="name" type="category" hide={true} />
                    <Tooltip />
                    <Bar dataKey="idle" stackId="a" fill="transparent" />
                    <Bar
                        dataKey="duration"
                        stackId="a"
                        fill="var(--chakra-colors-blue-400)"
                    >
                        {chartData.map((item, index) => {
                            return <Cell key={index} fill={item.customFill} />;
                        })}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
            <Text>Duration (ms)</Text>
        </Flex>
    );
};
