import {
    BaseKey,
    MetaQuery,
    useApiUrl,
    useCustomMutation,
    useResource,
    useTranslate,
} from "@refinedev/core";
import { useState } from "react";

export type FileExportOptions = {
    /**
     * Resource name for API data interactions.
     * @default Resource name that it reads from route
     */
    resource?: string;
    /**
     * Id of the resource
     */
    id?: BaseKey;
    /**
     * Path on the resource to be used for the file upload
     * @default import
     */
    path?: string;
    /**
     * File extension to be used for the export
     * @default csv
     */
    fileExtension?: string;
    /**
     * Custom body that is attached to the default body
     */
    customBody?: any;
    /**
     *  Metadata query for `dataProvider`
     */
    meta?: MetaQuery;
    /**
     * Called when the export was done
     */
    onFinish?: () => void;
};

export type UseFileExportReturnType = {
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    isLoading: boolean;
};

/**
 * `useFileExport` will download export data by downloading a CSV from the server
 */
export const useFileExport = ({
    resource: resourceFromProps,
    id,
    path = "export",
    fileExtension = "csv",
    meta,
    customBody,
    onFinish,
}: FileExportOptions): UseFileExportReturnType => {
    const [isLoading, setIsLoading] = useState(false);
    const translate = useTranslate();
    const { resource } = useResource(resourceFromProps);
    const apiUrl = useApiUrl();

    if (id) {
        path = `${id}/${path}`;
    }

    var endpoint: string;
    if (meta?.parent) {
        endpoint = `${apiUrl}/v1/${meta.parent}/${resource!.name}/${path}`;
    } else {
        endpoint = `${apiUrl}/v1/${resource!.name}/${path}`;
    }

    var filename: string;
    if (id) {
        filename = `${resource!.name}-${id}-${new Date().toISOString()}.${fileExtension}`;
    } else {
        filename = `${resource!.name}-${new Date().toISOString()}.${fileExtension}`;
    }

    const { mutate } = useCustomMutation<Blob>();

    const handleClick = () => {
        return new Promise<void>((resolve) => {
            setIsLoading(true);
            mutate(
                {
                    url: endpoint,
                    method: "post",
                    values: customBody ?? {},
                    successNotification: (data, values) => {
                        return {
                            message: translate(
                                `notifications.success`,
                                "Successful"
                            ),
                            description: translate(
                                `notifications.exportSuccess`,
                                "Successfully exported to a file"
                            ),
                            type: "success",
                        };
                    },
                    meta: {
                        responseType: "blob",
                    },
                },
                {
                    onError: (error, variables, context) => {
                        resolve();
                    },
                    onSuccess: (data, variables, context) => {
                        const fileURL = window.URL.createObjectURL(data.data);

                        // Create a temp <a> tag to download file
                        const link = document.createElement("a");
                        link.href = fileURL;
                        link.setAttribute("download", filename); // Set the file name you want
                        document.body.appendChild(link);

                        // Simulate click on the temp link to trigger the download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode?.removeChild(link);

                        resolve();
                    },
                }
            );
        }).then(() => {
            setIsLoading(false);
            onFinish?.();
        });
    };

    return {
        onClick: handleClick,
        isLoading,
    };
};
