import {
    Box,
    Button,
    Heading,
    IconButton,
    Stack,
    Text,
    Tooltip,
    useColorModeValue,
} from "@chakra-ui/react";
import {
    useNavigation,
    useResource,
    useTranslate
} from "@refinedev/core";
import React, { useEffect, useState } from "react";
import { FiAlertCircle } from "react-icons/fi";

export const ErrorComponent: React.FC = () => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const { push } = useNavigation();
    const translate = useTranslate();

    const { resource, action } = useResource();

    console.log("error component resource", resource);

    useEffect(() => {
        const resourceName = resource?.name;

        if (!resourceName) {
            setErrorMessage(
                translate(
                    "pages.error.notfound.info",
                    `The resource does not exist`
                )
            );
        } else {
            setErrorMessage(
                translate(
                    "pages.error.forbidden.info",
                    {
                        action,
                        resource: resourceName,
                    },
                    `Your are not allowed action: {{action}} on resource: {{resource}}`
                )
            );
        }
    }, [resource]);

    const color = useColorModeValue("gray.500", "gray.400");

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minH="100vh"
            sx={{
                "@media (min-height: 755px)": {
                    marginTop: "-150px",
                },
            }}
        >
            <Heading fontWeight={900} fontSize={[120, 160, 220]} color={color}>
                {resource ? "403" : "404"}
            </Heading>
            <Stack
                direction={["column", "row"]}
                alignItems="center"
                spacing={2}
            >
                <Text color={color} fontSize="xl" align="center">
                    {resource
                        ? translate(
                              "pages.error.forbidden.error",
                              "Sorry, the page you are trying to reach does not exist."
                          )
                        : translate(
                              "pages.error.notfound.error",
                              "Sorry, you are not allowed to view this page."
                          )}
                </Text>
                {errorMessage && (
                    <Tooltip openDelay={0} label={errorMessage}>
                        <IconButton
                            icon={<FiAlertCircle />}
                            aria-label="info"
                            color={color}
                        />
                    </Tooltip>
                )}
            </Stack>
            <Button mt={6} variant="action" onClick={() => push("/")}>
                {translate("pages.error.backHome", "Back Home")}
            </Button>
        </Box>
    );
};
