import {
    Badge,
    BoxProps,
    Button,
    ButtonProps,
    Flex,
    IconButton,
    Spinner,
} from "@chakra-ui/react";
import {
    MetaQuery,
    useApiUrl,
    useCustomMutation,
    useNotification,
    useResourceParams,
    useTranslate,
    useUpdate,
} from "@refinedev/core";
import { id } from "date-fns/locale";
import React from "react";
import { FiCheck } from "react-icons/fi";
import { compareAsc, format } from "date-fns";

export interface StatusButtonProps extends BoxProps {
    recordItemId: string;
    status: "active" | "draft";
    meta?: MetaQuery;
    asMultiPart?: boolean;
    startDate?: Date;
    endDate?: Date;    
}

export const StatusButton: React.FC<StatusButtonProps> = ({
    recordItemId,
    status,
    meta,
    asMultiPart = false,
    startDate,
    endDate,
    ...props
}) => {
    const translate = useTranslate();
    const { open } = useNotification();
    const { resource } = useResourceParams();
    const { mutate, isLoading } = useUpdate({
        resource: resource!.name,
    });

    var colorScheme = "green";
    switch (status) {
        case "active":
            colorScheme = "green";
            break;
        case "draft":
            colorScheme = "blue";
            break;
        default:
            colorScheme = "green";
    }

    var statusLabel = status.toString();
    if (status == "active" && endDate && compareAsc(new Date(), endDate) > 0) {
        statusLabel = "expired"
        colorScheme = "red"
    } else if (status == "active" && startDate && compareAsc(new Date(), startDate) < 0) {
        statusLabel = "pending"
        colorScheme = "yellow"
    }

    const onClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        var values: any;
        if (asMultiPart) {
            const formData = new FormData();
            const body = {
                status: status === "active" ? "draft" : "active",
            };
            formData.append("body", JSON.stringify({ ...body }));
            values = formData;
        } else {
            values = {
                status: status === "active" ? "draft" : "active",
            };
        }

        mutate({
            id: recordItemId,
            values: values,
        });
    };

    return isLoading ? (
        <Flex align="center">
            <Spinner ml={3} />
        </Flex>
    ) : (
        <Badge
            onClick={onClick}
            as={Badge}
            colorScheme={colorScheme}
            variant="subtle"
            fontSize="0.8em"
            _hover={{ cursor: "pointer", filter: "brightness(0.9)" }}
            transition={"all 0.2s ease-in-out"}
            {...props}
        >
            {statusLabel}
        </Badge>
    );
};
