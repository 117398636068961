import { useForm } from "@refinedev/react-hook-form";
import {
    InputFormControl,
    SelectFormControl,
} from "@bankingright-dashboard/table";
import { FormType } from "@bankingright-dashboard/enums";
import { ICallToAction } from "@bankingright-dashboard/interfaces";
import { useTranslate } from "@refinedev/core";
import { Clone, Create, Edit } from "@bankingright-dashboard/ui";
import {
    FormControl,
    FormLabel,
    Select,
    FormErrorMessage,
    Checkbox,
} from "@chakra-ui/react";
import { covertToOptionArray } from "@bankingright-dashboard/utils";

interface CallToActionFormProps {
    type: FormType;
}

export const CallToActionForm = ({ type }: CallToActionFormProps) => {
    const translate = useTranslate();

    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
        control,
    } = useForm<ICallToAction>();

    const form = () => {
        return (
            <>
                <SelectFormControl
                    name="platform"
                    label="Platform"
                    control={control}
                    options={covertToOptionArray(["ios", "android"])}
                />
                <InputFormControl
                    name={"appVersion"}
                    label={translate(
                        "pages.calltoaction.appVersion",
                        "App Version"
                    )}
                    control={control}
                />
                <InputFormControl
                    name={"platformVersion"}
                    label={translate(
                        "pages.calltoaction.platformVersion",
                        "Platform Version"
                    )}
                    control={control}
                />
                <InputFormControl
                    name={"title"}
                    label={translate("pages.calltoaction.title", "Title")}
                    control={control}
                />
                <InputFormControl
                    name={"body"}
                    label={translate("pages.calltoaction.body", "Body")}
                    control={control}
                />
                <InputFormControl
                    name={"actionUrl"}
                    label={translate(
                        "pages.calltoaction.actionUrl",
                        "Action URL"
                    )}
                    control={control}
                />
                <InputFormControl
                    name={"buttonText"}
                    label={translate(
                        "pages.calltoaction.buttonText",
                        "Button Text"
                    )}
                    control={control}
                />
                <FormControl mb="3" isInvalid={!!errors?.status}>
                    <FormLabel>
                        {translate("pages.calltoaction.blocking", "Blocking")}
                    </FormLabel>
                    <Checkbox id="blocking" {...register("blocking")}>
                        {translate("pages.calltoaction.blocking", "Blocking")}
                    </Checkbox>
                </FormControl>
            </>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
        case FormType.clone:
            return (
                <Clone
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Clone>
            );
        default:
            return <></>;
    }
};
